import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import React, { ReactElement } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import ScrollToHashElement from './components/utils/ScrollToHashElement';
import { theme } from './config/ThemeOptions';
import { ApplicationRoutes } from './routes/ApplicationRoutes';
import { CookieConsentComponent } from './components/cookieConsent/CookieConsentComponenet';
function App(): ReactElement {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Router>
                    <ApplicationRoutes />
                    <ScrollToHashElement />
                    <CookieConsentComponent />
                </Router>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default App;
