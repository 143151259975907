import { Box, Container, Grid } from '@mui/material';
import { useTheme } from '@mui/material';
import AnchorLink from '@mui/material/Link';

import { ContentBlock } from '../start/components/components/ContentBlock';

export function ContactFormResponsePage() {
    const theme = useTheme();

    return (
        <Box
            sx={{
                backgroundColor: theme.palette.background.paper,
                width: '100vw',
                marginTop: theme.spacing(12),
                marginBottom: theme.spacing(12),
            }}
        >
            <Container>
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <ContentBlock header={'Danke!'}>
                            Ihre Nachricht wurde erfolgreich gesendet. Wir
                            werden uns in Kürze bei Ihnen melden.
                            <br />
                            <AnchorLink href="/">
                                Zurück zur Startseite
                            </AnchorLink>
                        </ContentBlock>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
