/* eslint-disable no-irregular-whitespace */

import { Box, Container, Grid } from '@mui/material';
import { theme } from '../../config/ThemeOptions';
import { ContentBlock } from './../start/components/components/ContentBlock';

export function DataProtection() {
    return (
        <Box
            sx={{
                backgroundColor: theme.palette.background.paper,
                width: '100vw',
                marginTop: theme.spacing(12),
                marginBottom: theme.spacing(12),
            }}
        >
            <Container>
                <Grid container>
                    <Grid item>
                        <ContentBlock header="Datenschutzerklärung">
                            <h2 id="m716">Präambel</h2>
                            <p>
                                Mit der folgenden Datenschutzerklärung möchten
                                wir Sie darüber aufklären, welche Arten Ihrer
                                personenbezogenen Daten (nachfolgend auch kurz
                                als "Daten" bezeichnet) wir zu welchen Zwecken
                                und in welchem Umfang verarbeiten. Die
                                Datenschutzerklärung gilt für alle von uns
                                durchgeführten Verarbeitungen personenbezogener
                                Daten, sowohl im Rahmen der Erbringung unserer
                                Leistungen als auch insbesondere auf unseren
                                Webseiten, in mobilen Applikationen sowie
                                innerhalb externer Onlinepräsenzen, wie z. B.
                                unserer Social-Media-Profile (nachfolgend
                                zusammenfassend bezeichnet als "Onlineangebot").
                            </p>
                            <p>
                                Die verwendeten Begriffe sind nicht
                                geschlechtsspezifisch.
                            </p>
                            <p>Stand: 18. Oktober 2024</p>
                            <h2>Inhaltsübersicht</h2>{' '}
                            <ul className="index">
                                <li>
                                    <a className="index-link" href="#m716">
                                        Präambel
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m3">
                                        Verantwortlicher
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#mOverview">
                                        Übersicht der Verarbeitungen
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m2427">
                                        Maßgebliche Rechtsgrundlagen
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m27">
                                        Sicherheitsmaßnahmen
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m25">
                                        Übermittlung von personenbezogenen Daten
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m24">
                                        Internationale Datentransfers
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m12">
                                        Allgemeine Informationen zur
                                        Datenspeicherung und Löschung
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m10">
                                        Rechte der betroffenen Personen
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m317">
                                        Geschäftliche Leistungen
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m225">
                                        Bereitstellung des Onlineangebots und
                                        Webhosting
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m134">
                                        Einsatz von Cookies
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m182">
                                        Kontakt- und Anfrageverwaltung
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m17">
                                        Newsletter und elektronische
                                        Benachrichtigungen
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m638">
                                        Werbliche Kommunikation via E-Mail,
                                        Post, Fax oder Telefon
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m263">
                                        Webanalyse, Monitoring und Optimierung
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m264">
                                        Onlinemarketing
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m15">
                                        Änderung und Aktualisierung
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m42">
                                        Begriffsdefinitionen
                                    </a>
                                </li>
                            </ul>
                            <h2 id="m3">Verantwortlicher</h2>
                            <p>
                                Vorname, Name / Firma
                                <br />
                                Straße, Hausnr.
                                <br />
                                PLZ, Ort, Land
                            </p>
                            <p>
                                E-Mail-Adresse:{' '}
                                <a href="mailto:vorname.name@beispielsdomain.eu">
                                    vorname.name@beispielsdomain.eu
                                </a>
                            </p>
                            <h2 id="mOverview">Übersicht der Verarbeitungen</h2>
                            <p>
                                Die nachfolgende Übersicht fasst die Arten der
                                verarbeiteten Daten und die Zwecke ihrer
                                Verarbeitung zusammen und verweist auf die
                                betroffenen Personen.
                            </p>
                            <h3>Arten der verarbeiteten Daten</h3>
                            <ul>
                                <li>Bestandsdaten.</li>
                                <li>Zahlungsdaten.</li>
                                <li>Kontaktdaten.</li>
                                <li>Inhaltsdaten.</li>
                                <li>Vertragsdaten.</li>
                                <li>Nutzungsdaten.</li>
                                <li>
                                    Meta-, Kommunikations- und Verfahrensdaten.
                                </li>
                                <li>Protokolldaten.</li>
                            </ul>
                            <h3>Kategorien betroffener Personen</h3>
                            <ul>
                                <li>Leistungsempfänger und Auftraggeber.</li>
                                <li>Interessenten.</li>
                                <li>Kommunikationspartner.</li>
                                <li>Nutzer.</li>
                                <li>Geschäfts- und Vertragspartner.</li>
                            </ul>
                            <h3>Zwecke der Verarbeitung</h3>
                            <ul>
                                <li>
                                    Erbringung vertraglicher Leistungen und
                                    Erfüllung vertraglicher Pflichten.
                                </li>
                                <li>Kommunikation.</li>
                                <li>Sicherheitsmaßnahmen.</li>
                                <li>Direktmarketing.</li>
                                <li>Reichweitenmessung.</li>
                                <li>Tracking.</li>
                                <li>Büro- und Organisationsverfahren.</li>
                                <li>Konversionsmessung.</li>
                                <li>Zielgruppenbildung.</li>
                                <li>
                                    Organisations- und Verwaltungsverfahren.
                                </li>
                                <li>Feedback.</li>
                                <li>Marketing.</li>
                                <li>
                                    Profile mit nutzerbezogenen Informationen.
                                </li>
                                <li>
                                    Bereitstellung unseres Onlineangebotes und
                                    Nutzerfreundlichkeit.
                                </li>
                                <li>Informationstechnische Infrastruktur.</li>
                                <li>Absatzförderung.</li>
                                <li>
                                    Geschäftsprozesse und
                                    betriebswirtschaftliche Verfahren.
                                </li>
                            </ul>
                            <h2 id="m2427">Maßgebliche Rechtsgrundlagen</h2>
                            <p>
                                <strong>
                                    Maßgebliche Rechtsgrundlagen nach der DSGVO:{' '}
                                </strong>
                                Im Folgenden erhalten Sie eine Übersicht der
                                Rechtsgrundlagen der DSGVO, auf deren Basis wir
                                personenbezogene Daten verarbeiten. Bitte nehmen
                                Sie zur Kenntnis, dass neben den Regelungen der
                                DSGVO nationale Datenschutzvorgaben in Ihrem
                                bzw. unserem Wohn- oder Sitzland gelten können.
                                Sollten ferner im Einzelfall speziellere
                                Rechtsgrundlagen maßgeblich sein, teilen wir
                                Ihnen diese in der Datenschutzerklärung mit.
                            </p>
                            <ul>
                                <li>
                                    <strong>
                                        Einwilligung (Art. 6 Abs. 1 S. 1 lit. a)
                                        DSGVO)
                                    </strong>{' '}
                                    - Die betroffene Person hat ihre
                                    Einwilligung in die Verarbeitung der sie
                                    betreffenden personenbezogenen Daten für
                                    einen spezifischen Zweck oder mehrere
                                    bestimmte Zwecke gegeben.
                                </li>
                                <li>
                                    <strong>
                                        Vertragserfüllung und vorvertragliche
                                        Anfragen (Art. 6 Abs. 1 S. 1 lit. b)
                                        DSGVO)
                                    </strong>{' '}
                                    - Die Verarbeitung ist für die Erfüllung
                                    eines Vertrags, dessen Vertragspartei die
                                    betroffene Person ist, oder zur Durchführung
                                    vorvertraglicher Maßnahmen erforderlich, die
                                    auf Anfrage der betroffenen Person erfolgen.
                                </li>
                                <li>
                                    <strong>
                                        Rechtliche Verpflichtung (Art. 6 Abs. 1
                                        S. 1 lit. c) DSGVO)
                                    </strong>{' '}
                                    - Die Verarbeitung ist zur Erfüllung einer
                                    rechtlichen Verpflichtung erforderlich, der
                                    der Verantwortliche unterliegt.
                                </li>
                                <li>
                                    <strong>
                                        Berechtigte Interessen (Art. 6 Abs. 1 S.
                                        1 lit. f) DSGVO)
                                    </strong>{' '}
                                    - die Verarbeitung ist zur Wahrung der
                                    berechtigten Interessen des Verantwortlichen
                                    oder eines Dritten notwendig, vorausgesetzt,
                                    dass die Interessen, Grundrechte und
                                    Grundfreiheiten der betroffenen Person, die
                                    den Schutz personenbezogener Daten
                                    verlangen, nicht überwiegen.
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    Drittland (außerhalb der EU und der
                                    Schweiz):{' '}
                                </strong>
                                Es gelten die Datenschutzregelungen in dem
                                Sitzland des Verantwortlichen zusätzlich oder
                                neben den Datenschutzregelungen der DSGVO. Diese
                                Regelungen können spezifische Bestimmungen
                                enthalten, die über die Anforderungen der DSGVO
                                hinausgehen oder davon abweichen. Dazu gehören
                                unter anderem Vorschriften zum Schutz vor
                                Missbrauch personenbezogener Daten, Regelungen
                                zu Auskunfts- und Löschungsrechten,
                                Widerspruchsrechten, Verarbeitung besonderer
                                Kategorien personenbezogener Daten, Verarbeitung
                                für andere Zwecke, Übermittlung und
                                automatisierte Entscheidungsfindung
                                einschließlich Profiling.
                            </p>
                            <h2 id="m27">Sicherheitsmaßnahmen</h2>
                            <p>
                                Wir treffen nach Maßgabe der gesetzlichen
                                Vorgaben unter Berücksichtigung des Stands der
                                Technik, der Implementierungskosten und der Art,
                                des Umfangs, der Umstände und der Zwecke der
                                Verarbeitung sowie der unterschiedlichen
                                Eintrittswahrscheinlichkeiten und des Ausmaßes
                                der Bedrohung der Rechte und Freiheiten
                                natürlicher Personen geeignete technische und
                                organisatorische Maßnahmen, um ein dem Risiko
                                angemessenes Schutzniveau zu gewährleisten.
                            </p>
                            <p>
                                Zu den Maßnahmen gehören insbesondere die
                                Sicherung der Vertraulichkeit, Integrität und
                                Verfügbarkeit von Daten durch Kontrolle des
                                physischen und elektronischen Zugangs zu den
                                Daten als auch des sie betreffenden Zugriffs,
                                der Eingabe, der Weitergabe, der Sicherung der
                                Verfügbarkeit und ihrer Trennung. Des Weiteren
                                haben wir Verfahren eingerichtet, die eine
                                Wahrnehmung von Betroffenenrechten, die Löschung
                                von Daten und Reaktionen auf die Gefährdung der
                                Daten gewährleisten. Ferner berücksichtigen wir
                                den Schutz personenbezogener Daten bereits bei
                                der Entwicklung bzw. Auswahl von Hardware,
                                Software sowie Verfahren entsprechend dem
                                Prinzip des Datenschutzes, durch
                                Technikgestaltung und durch
                                datenschutzfreundliche Voreinstellungen.
                            </p>
                            <p>
                                Sicherung von Online-Verbindungen durch
                                TLS-/SSL-Verschlüsselungstechnologie (HTTPS): Um
                                die Daten der Nutzer, die über unsere
                                Online-Dienste übertragen werden, vor
                                unerlaubten Zugriffen zu schützen, setzen wir
                                auf die TLS-/SSL-Verschlüsselungstechnologie.
                                Secure Sockets Layer (SSL) und Transport Layer
                                Security (TLS) sind die Eckpfeiler der sicheren
                                Datenübertragung im Internet. Diese Technologien
                                verschlüsseln die Informationen, die zwischen
                                der Website oder App und dem Browser des Nutzers
                                (oder zwischen zwei Servern) übertragen werden,
                                wodurch die Daten vor unbefugtem Zugriff
                                geschützt sind. TLS, als die weiterentwickelte
                                und sicherere Version von SSL, gewährleistet,
                                dass alle Datenübertragungen den höchsten
                                Sicherheitsstandards entsprechen. Wenn eine
                                Website durch ein SSL-/TLS-Zertifikat gesichert
                                ist, wird dies durch die Anzeige von HTTPS in
                                der URL signalisiert. Dies dient als ein
                                Indikator für die Nutzer, dass ihre Daten sicher
                                und verschlüsselt übertragen werden.
                            </p>
                            <h2 id="m25">
                                Übermittlung von personenbezogenen Daten
                            </h2>
                            <p>
                                Im Rahmen unserer Verarbeitung von
                                personenbezogenen Daten kommt es vor, dass diese
                                an andere Stellen, Unternehmen, rechtlich
                                selbstständige Organisationseinheiten oder
                                Personen übermittelt beziehungsweise ihnen
                                gegenüber offengelegt werden. Zu den Empfängern
                                dieser Daten können z. B. mit IT-Aufgaben
                                beauftragte Dienstleister gehören oder Anbieter
                                von Diensten und Inhalten, die in eine Website
                                eingebunden sind. In solchen Fällen beachten wir
                                die gesetzlichen Vorgaben und schließen
                                insbesondere entsprechende Verträge bzw.
                                Vereinbarungen, die dem Schutz Ihrer Daten
                                dienen, mit den Empfängern Ihrer Daten ab.
                            </p>
                            <h2 id="m24">Internationale Datentransfers</h2>
                            <p>
                                Datenverarbeitung in Drittländern: Sofern wir
                                Daten in einem Drittland (d. h., außerhalb der
                                Europäischen Union (EU), des Europäischen
                                Wirtschaftsraums (EWR)) verarbeiten oder die
                                Verarbeitung im Rahmen der Inanspruchnahme von
                                Diensten Dritter oder der Offenlegung bzw.
                                Übermittlung von Daten an andere Personen,
                                Stellen oder Unternehmen stattfindet, erfolgt
                                dies nur im Einklang mit den gesetzlichen
                                Vorgaben. Sofern das Datenschutzniveau in dem
                                Drittland mittels eines
                                Angemessenheitsbeschlusses anerkannt wurde (Art.
                                45 DSGVO), dient dieser als Grundlage des
                                Datentransfers. Im Übrigen erfolgen
                                Datentransfers nur dann, wenn das
                                Datenschutzniveau anderweitig gesichert ist,
                                insbesondere durch Standardvertragsklauseln
                                (Art. 46 Abs. 2 lit. c) DSGVO), ausdrückliche
                                Einwilligung oder im Fall vertraglicher oder
                                gesetzlich erforderlicher Übermittlung (Art. 49
                                Abs. 1 DSGVO). Im Übrigen teilen wir Ihnen die
                                Grundlagen der Drittlandübermittlung bei den
                                einzelnen Anbietern aus dem Drittland mit, wobei
                                die Angemessenheitsbeschlüsse als Grundlagen
                                vorrangig gelten. Informationen zu
                                Drittlandtransfers und vorliegenden
                                Angemessenheitsbeschlüssen können dem
                                Informationsangebot der EU-Kommission entnommen
                                werden:{' '}
                                <a
                                    href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en?prefLang=de"
                                    target="_blank"
                                >
                                    https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en?prefLang=de.
                                </a>{' '}
                                Im Rahmen des sogenannten „Data Privacy
                                Framework" (DPF) hat die EU-Kommission das
                                Datenschutzniveau ebenfalls für bestimmte
                                Unternehmen aus den USA im Rahmen der
                                Angemessenheitsbeschlusses vom 10.07.2023 als
                                sicher anerkannt. Die Liste der zertifizierten
                                Unternehmen als auch weitere Informationen zu
                                dem DPF können Sie der Website des
                                Handelsministeriums der USA unter{' '}
                                <a
                                    href="https://www.dataprivacyframework.gov/"
                                    target="_blank"
                                >
                                    https://www.dataprivacyframework.gov/
                                </a>{' '}
                                (in Englisch) entnehmen. Wir informieren Sie im
                                Rahmen der Datenschutzhinweise, welche von uns
                                eingesetzten Diensteanbieter unter dem Data
                                Privacy Framework zertifiziert sind.
                            </p>
                            <h2 id="m12">
                                Allgemeine Informationen zur Datenspeicherung
                                und Löschung
                            </h2>
                            <p>
                                Wir löschen personenbezogene Daten, die wir
                                verarbeiten, gemäß den gesetzlichen
                                Bestimmungen, sobald die zugrundeliegenden
                                Einwilligungen widerrufen werden oder keine
                                weiteren rechtlichen Grundlagen für die
                                Verarbeitung bestehen. Dies betrifft Fälle, in
                                denen der ursprüngliche Verarbeitungszweck
                                entfällt oder die Daten nicht mehr benötigt
                                werden. Ausnahmen von dieser Regelung bestehen,
                                wenn gesetzliche Pflichten oder besondere
                                Interessen eine längere Aufbewahrung oder
                                Archivierung der Daten erfordern.
                            </p>
                            <p>
                                Insbesondere müssen Daten, die aus handels- oder
                                steuerrechtlichen Gründen aufbewahrt werden
                                müssen oder deren Speicherung notwendig ist zur
                                Rechtsverfolgung oder zum Schutz der Rechte
                                anderer natürlicher oder juristischer Personen,
                                entsprechend archiviert werden.
                            </p>
                            <p>
                                Unsere Datenschutzhinweise enthalten zusätzliche
                                Informationen zur Aufbewahrung und Löschung von
                                Daten, die speziell für bestimmte
                                Verarbeitungsprozesse gelten.
                            </p>
                            <p>
                                Bei mehreren Angaben zur Aufbewahrungsdauer oder
                                Löschungsfristen eines Datums, ist stets die
                                längste Frist maßgeblich.
                            </p>
                            <p>
                                Beginnt eine Frist nicht ausdrücklich zu einem
                                bestimmten Datum und beträgt sie mindestens ein
                                Jahr, so startet sie automatisch am Ende des
                                Kalenderjahres, in dem das fristauslösende
                                Ereignis eingetreten ist. Im Fall laufender
                                Vertragsverhältnisse, in deren Rahmen Daten
                                gespeichert werden, ist das fristauslösende
                                Ereignis der Zeitpunkt des Wirksamwerdens der
                                Kündigung oder sonstige Beendigung des
                                Rechtsverhältnisses.
                            </p>
                            <p>
                                Daten, die nicht mehr für den ursprünglich
                                vorgesehenen Zweck, sondern aufgrund
                                gesetzlicher Vorgaben oder anderer Gründe
                                aufbewahrt werden, verarbeiten wir
                                ausschließlich zu den Gründen, die ihre
                                Aufbewahrung rechtfertigen.
                            </p>
                            <h2 id="m10">Rechte der betroffenen Personen</h2>
                            <p>
                                Rechte der betroffenen Personen aus der DSGVO:
                                Ihnen stehen als Betroffene nach der DSGVO
                                verschiedene Rechte zu, die sich insbesondere
                                aus Art. 15 bis 21 DSGVO ergeben:
                            </p>
                            <ul>
                                <li>
                                    <strong>
                                        Widerspruchsrecht: Sie haben das Recht,
                                        aus Gründen, die sich aus Ihrer
                                        besonderen Situation ergeben, jederzeit
                                        gegen die Verarbeitung der Sie
                                        betreffenden personenbezogenen Daten,
                                        die aufgrund von Art. 6 Abs. 1 lit. e
                                        oder f DSGVO erfolgt, Widerspruch
                                        einzulegen; dies gilt auch für ein auf
                                        diese Bestimmungen gestütztes Profiling.
                                        Werden die Sie betreffenden
                                        personenbezogenen Daten verarbeitet, um
                                        Direktwerbung zu betreiben, haben Sie
                                        das Recht, jederzeit Widerspruch gegen
                                        die Verarbeitung der Sie betreffenden
                                        personenbezogenen Daten zum Zwecke
                                        derartiger Werbung einzulegen; dies gilt
                                        auch für das Profiling, soweit es mit
                                        solcher Direktwerbung in Verbindung
                                        steht.
                                    </strong>
                                </li>
                                <li>
                                    <strong>
                                        Widerrufsrecht bei Einwilligungen:
                                    </strong>{' '}
                                    Sie haben das Recht, erteilte Einwilligungen
                                    jederzeit zu widerrufen.
                                </li>
                                <li>
                                    <strong>Auskunftsrecht:</strong> Sie haben
                                    das Recht, eine Bestätigung darüber zu
                                    verlangen, ob betreffende Daten verarbeitet
                                    werden und auf Auskunft über diese Daten
                                    sowie auf weitere Informationen und Kopie
                                    der Daten entsprechend den gesetzlichen
                                    Vorgaben.
                                </li>
                                <li>
                                    <strong>Recht auf Berichtigung:</strong> Sie
                                    haben entsprechend den gesetzlichen Vorgaben
                                    das Recht, die Vervollständigung der Sie
                                    betreffenden Daten oder die Berichtigung der
                                    Sie betreffenden unrichtigen Daten zu
                                    verlangen.
                                </li>
                                <li>
                                    <strong>
                                        Recht auf Löschung und Einschränkung der
                                        Verarbeitung:
                                    </strong>{' '}
                                    Sie haben nach Maßgabe der gesetzlichen
                                    Vorgaben das Recht, zu verlangen, dass Sie
                                    betreffende Daten unverzüglich gelöscht
                                    werden, bzw. alternativ nach Maßgabe der
                                    gesetzlichen Vorgaben eine Einschränkung der
                                    Verarbeitung der Daten zu verlangen.
                                </li>
                                <li>
                                    <strong>
                                        Recht auf Datenübertragbarkeit:
                                    </strong>{' '}
                                    Sie haben das Recht, Sie betreffende Daten,
                                    die Sie uns bereitgestellt haben, nach
                                    Maßgabe der gesetzlichen Vorgaben in einem
                                    strukturierten, gängigen und
                                    maschinenlesbaren Format zu erhalten oder
                                    deren Übermittlung an einen anderen
                                    Verantwortlichen zu fordern.
                                </li>
                                <li>
                                    <strong>
                                        Beschwerde bei Aufsichtsbehörde:
                                    </strong>{' '}
                                    Sie haben unbeschadet eines anderweitigen
                                    verwaltungsrechtlichen oder gerichtlichen
                                    Rechtsbehelfs das Recht auf Beschwerde bei
                                    einer Aufsichtsbehörde, insbesondere in dem
                                    Mitgliedstaat ihres gewöhnlichen
                                    Aufenthaltsorts, ihres Arbeitsplatzes oder
                                    des Orts des mutmaßlichen Verstoßes, wenn
                                    Sie der Ansicht sind, dass die Verarbeitung
                                    der Sie betreffenden personenbezogenen Daten
                                    gegen die Vorgaben der DSGVO verstößt.
                                </li>
                            </ul>
                            <h2 id="m317">Geschäftliche Leistungen</h2>
                            <p>
                                Wir verarbeiten Daten unserer Vertrags- und
                                Geschäftspartner, z. B. Kunden und Interessenten
                                (zusammenfassend als „Vertragspartner"
                                bezeichnet), im Rahmen von vertraglichen und
                                vergleichbaren Rechtsverhältnissen sowie damit
                                verbundenen Maßnahmen und im Hinblick auf die
                                Kommunikation mit den Vertragspartnern (oder
                                vorvertraglich), etwa zur Beantwortung von
                                Anfragen.
                            </p>
                            <p>
                                Wir verwenden diese Daten, um unsere
                                vertraglichen Verpflichtungen zu erfüllen. Dazu
                                gehören insbesondere die Pflichten zur
                                Erbringung der vereinbarten Leistungen, etwaige
                                Aktualisierungspflichten und Abhilfe bei
                                Gewährleistungs- und sonstigen
                                Leistungsstörungen. Darüber hinaus verwenden wir
                                die Daten zur Wahrung unserer Rechte und zum
                                Zwecke der mit diesen Pflichten verbundenen
                                Verwaltungsaufgaben sowie der
                                Unternehmensorganisation. Zudem verarbeiten wir
                                die Daten auf Grundlage unserer berechtigten
                                Interessen sowohl an einer ordnungsgemäßen und
                                betriebswirtschaftlichen Geschäftsführung als
                                auch an Sicherheitsmaßnahmen zum Schutz unserer
                                Vertragspartner und unseres Geschäftsbetriebs
                                vor Missbrauch, Gefährdung ihrer Daten,
                                Geheimnisse, Informationen und Rechte (z. B. zur
                                Beteiligung von Telekommunikations-, Transport-
                                und sonstigen Hilfsdiensten sowie
                                Subunternehmern, Banken, Steuer- und
                                Rechtsberatern, Zahlungsdienstleistern oder
                                Finanzbehörden). Im Rahmen des geltenden Rechts
                                geben wir die Daten von Vertragspartnern nur
                                insoweit an Dritte weiter, als dies für die
                                vorgenannten Zwecke oder zur Erfüllung
                                gesetzlicher Pflichten erforderlich ist. Über
                                weitere Formen der Verarbeitung, etwa zu
                                Marketingzwecken, werden die Vertragspartner im
                                Rahmen dieser Datenschutzerklärung informiert.
                            </p>
                            <p>
                                Welche Daten für die vorgenannten Zwecke
                                erforderlich sind, teilen wir den
                                Vertragspartnern vor oder im Rahmen der
                                Datenerhebung, z. B. in Onlineformularen, durch
                                besondere Kennzeichnung (z. B. Farben) bzw.
                                Symbole (z. B. Sternchen o. Ä.), oder persönlich
                                mit.
                            </p>
                            <p>
                                Wir löschen die Daten nach Ablauf gesetzlicher
                                Gewährleistungs- und vergleichbarer Pflichten,
                                d. h. grundsätzlich nach vier Jahren, es sei
                                denn, dass die Daten in einem Kundenkonto
                                gespeichert werden, z. B., solange sie aus
                                gesetzlichen Gründen der Archivierung aufbewahrt
                                werden müssen (etwa für Steuerzwecke im
                                Regelfall zehn Jahre). Daten, die uns im Rahmen
                                eines Auftrags durch den Vertragspartner
                                offengelegt wurden, löschen wir entsprechend den
                                Vorgaben und grundsätzlich nach Ende des
                                Auftrags.
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Verarbeitete Datenarten:</strong>{' '}
                                    Bestandsdaten (z. B. der vollständige Name,
                                    Wohnadresse, Kontaktinformationen,
                                    Kundennummer, etc.); Zahlungsdaten (z. B.
                                    Bankverbindungen, Rechnungen,
                                    Zahlungshistorie); Kontaktdaten (z. B. Post-
                                    und E-Mail-Adressen oder Telefonnummern).
                                    Vertragsdaten (z. B. Vertragsgegenstand,
                                    Laufzeit, Kundenkategorie).
                                </li>
                                <li>
                                    <strong>Betroffene Personen:</strong>{' '}
                                    Leistungsempfänger und Auftraggeber;
                                    Interessenten. Geschäfts- und
                                    Vertragspartner.
                                </li>
                                <li>
                                    <strong>Zwecke der Verarbeitung:</strong>{' '}
                                    Erbringung vertraglicher Leistungen und
                                    Erfüllung vertraglicher Pflichten;
                                    Kommunikation; Büro- und
                                    Organisationsverfahren; Organisations- und
                                    Verwaltungsverfahren. Geschäftsprozesse und
                                    betriebswirtschaftliche Verfahren.
                                </li>
                                <li>
                                    <strong>Aufbewahrung und Löschung:</strong>{' '}
                                    Löschung entsprechend Angaben im Abschnitt
                                    "Allgemeine Informationen zur
                                    Datenspeicherung und Löschung".
                                </li>
                                <li className="">
                                    <strong>Rechtsgrundlagen:</strong>{' '}
                                    Vertragserfüllung und vorvertragliche
                                    Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO);
                                    Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1
                                    lit. c) DSGVO). Berechtigte Interessen (Art.
                                    6 Abs. 1 S. 1 lit. f) DSGVO).
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    Weitere Hinweise zu Verarbeitungsprozessen,
                                    Verfahren und Diensten:
                                </strong>
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Agenturdienstleistungen: </strong>
                                    Wir verarbeiten die Daten unserer Kunden im
                                    Rahmen unserer vertraglichen Leistungen, zu
                                    denen z. B. konzeptionelle und strategische
                                    Beratung, Kampagnenplanung, Software- und
                                    Designentwicklung/-beratung oder -pflege,
                                    Umsetzung von Kampagnen und Prozessen,
                                    Handling, Serveradministration,
                                    Datenanalyse/ Beratungsleistungen und
                                    Schulungsleistungen gehören können;{' '}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong>{' '}
                                        Vertragserfüllung und vorvertragliche
                                        Anfragen (Art. 6 Abs. 1 S. 1 lit. b)
                                        DSGVO).
                                    </span>
                                </li>
                                <li>
                                    <strong>
                                        Projekt- und Entwicklungsleistungen:{' '}
                                    </strong>
                                    Wir verarbeiten die Daten unserer Kunden
                                    sowie Auftraggeber (nachfolgend einheitlich
                                    als "Kunden" bezeichnet), um ihnen die
                                    Auswahl, den Erwerb bzw. die Beauftragung
                                    der gewählten Leistungen oder Werke sowie
                                    verbundener Tätigkeiten als auch deren
                                    Bezahlung und Zurverfügungstellung bzw.
                                    Ausführung oder Erbringung zu ermöglichen.
                                    <br />
                                    <br />
                                    Die erforderlichen Angaben sind als solche
                                    im Rahmen des Auftrags-, Bestell- bzw.
                                    vergleichbaren Vertragsschlusses
                                    gekennzeichnet und umfassen die zur
                                    Leistungserbringung und Abrechnung
                                    benötigten Angaben sowie
                                    Kontaktinformationen, um etwaige
                                    Rücksprachen halten zu können. Soweit wir
                                    Zugang zu Informationen der Endkunden,
                                    Mitarbeitern oder anderer Personen erhalten,
                                    verarbeiten wir diese im Einklang mit den
                                    gesetzlichen und vertraglichen Vorgaben;{' '}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong>{' '}
                                        Vertragserfüllung und vorvertragliche
                                        Anfragen (Art. 6 Abs. 1 S. 1 lit. b)
                                        DSGVO).
                                    </span>
                                </li>
                                <li>
                                    <strong>
                                        Angebot von Software- und
                                        Plattformleistungen:{' '}
                                    </strong>
                                    Wir verarbeiten die Daten unserer Nutzer,
                                    angemeldeter und etwaiger Testnutzer
                                    (nachfolgend einheitlich als "Nutzer"
                                    bezeichnet), um ihnen gegenüber unsere
                                    vertraglichen Leistungen erbringen zu können
                                    sowie auf Grundlage berechtigter Interessen,
                                    um die Sicherheit unseres Angebotes
                                    gewährleisten und es weiterentwickeln zu
                                    können. Die erforderlichen Angaben sind als
                                    solche im Rahmen des Auftrags-, Bestell-
                                    bzw. vergleichbaren Vertragsschlusses
                                    gekennzeichnet und umfassen die zur
                                    Leistungserbringung und Abrechnung
                                    benötigten Angaben sowie
                                    Kontaktinformationen, um etwaige
                                    Rücksprachen halten zu können;{' '}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong>{' '}
                                        Vertragserfüllung und vorvertragliche
                                        Anfragen (Art. 6 Abs. 1 S. 1 lit. b)
                                        DSGVO).
                                    </span>
                                </li>
                                <li>
                                    <strong>
                                        Technische Dienstleistungen:{' '}
                                    </strong>
                                    Wir verarbeiten die Daten unserer Kunden
                                    sowie Auftraggeber (nachfolgend einheitlich
                                    als "Kunden" bezeichnet), um ihnen die
                                    Auswahl, den Erwerb bzw. die Beauftragung
                                    der gewählten Leistungen oder Werke sowie
                                    verbundener Tätigkeiten als auch deren
                                    Bezahlung und Zurverfügungstellung bzw.
                                    Ausführung oder Erbringung zu ermöglichen.
                                    <br />
                                    <br />
                                    Die erforderlichen Angaben sind als solche
                                    im Rahmen des Auftrags-, Bestell- bzw.
                                    vergleichbaren Vertragsschlusses
                                    gekennzeichnet und umfassen die zur
                                    Leistungserbringung und Abrechnung
                                    benötigten Angaben sowie
                                    Kontaktinformationen, um etwaige
                                    Rücksprachen halten zu können. Soweit wir
                                    Zugang zu Informationen der Endkunden,
                                    Mitarbeitern oder anderer Personen erhalten,
                                    verarbeiten wir diese im Einklang mit den
                                    gesetzlichen und vertraglichen Vorgaben;{' '}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong>{' '}
                                        Vertragserfüllung und vorvertragliche
                                        Anfragen (Art. 6 Abs. 1 S. 1 lit. b)
                                        DSGVO).
                                    </span>
                                </li>
                            </ul>
                            <h2 id="m225">
                                Bereitstellung des Onlineangebots und Webhosting
                            </h2>
                            <p>
                                Wir verarbeiten die Daten der Nutzer, um ihnen
                                unsere Online-Dienste zur Verfügung stellen zu
                                können. Zu diesem Zweck verarbeiten wir die
                                IP-Adresse des Nutzers, die notwendig ist, um
                                die Inhalte und Funktionen unserer
                                Online-Dienste an den Browser oder das Endgerät
                                der Nutzer zu übermitteln.
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Verarbeitete Datenarten:</strong>{' '}
                                    Nutzungsdaten (z. B. Seitenaufrufe und
                                    Verweildauer, Klickpfade, Nutzungsintensität
                                    und -frequenz, verwendete Gerätetypen und
                                    Betriebssysteme, Interaktionen mit Inhalten
                                    und Funktionen); Meta-, Kommunikations- und
                                    Verfahrensdaten (z. B. IP-Adressen,
                                    Zeitangaben, Identifikationsnummern,
                                    beteiligte Personen). Protokolldaten (z. B.
                                    Logfiles betreffend Logins oder den Abruf
                                    von Daten oder Zugriffszeiten.).
                                </li>
                                <li>
                                    <strong>Betroffene Personen:</strong> Nutzer
                                    (z. B. Webseitenbesucher, Nutzer von
                                    Onlinediensten).
                                </li>
                                <li>
                                    <strong>Zwecke der Verarbeitung:</strong>{' '}
                                    Bereitstellung unseres Onlineangebotes und
                                    Nutzerfreundlichkeit; Informationstechnische
                                    Infrastruktur (Betrieb und Bereitstellung
                                    von Informationssystemen und technischen
                                    Geräten (Computer, Server etc.).).
                                    Sicherheitsmaßnahmen.
                                </li>
                                <li>
                                    <strong>Aufbewahrung und Löschung:</strong>{' '}
                                    Löschung entsprechend Angaben im Abschnitt
                                    "Allgemeine Informationen zur
                                    Datenspeicherung und Löschung".
                                </li>
                                <li className="">
                                    <strong>Rechtsgrundlagen:</strong>{' '}
                                    Berechtigte Interessen (Art. 6 Abs. 1 S. 1
                                    lit. f) DSGVO).
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    Weitere Hinweise zu Verarbeitungsprozessen,
                                    Verfahren und Diensten:
                                </strong>
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>
                                        Bereitstellung Onlineangebot auf
                                        gemietetem Speicherplatz:{' '}
                                    </strong>
                                    Für die Bereitstellung unseres
                                    Onlineangebotes nutzen wir Speicherplatz,
                                    Rechenkapazität und Software, die wir von
                                    einem entsprechenden Serveranbieter (auch
                                    "Webhoster" genannt) mieten oder anderweitig
                                    beziehen;{' '}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong>{' '}
                                        Berechtigte Interessen (Art. 6 Abs. 1 S.
                                        1 lit. f) DSGVO).
                                    </span>
                                </li>
                                <li>
                                    <strong>
                                        Erhebung von Zugriffsdaten und Logfiles:{' '}
                                    </strong>
                                    Der Zugriff auf unser Onlineangebot wird in
                                    Form von sogenannten "Server-Logfiles"
                                    protokolliert. Zu den Serverlogfiles können
                                    die Adresse und der Name der abgerufenen
                                    Webseiten und Dateien, Datum und Uhrzeit des
                                    Abrufs, übertragene Datenmengen, Meldung
                                    über erfolgreichen Abruf, Browsertyp nebst
                                    Version, das Betriebssystem des Nutzers,
                                    Referrer URL (die zuvor besuchte Seite) und
                                    im Regelfall IP-Adressen und der anfragende
                                    Provider gehören. Die Serverlogfiles können
                                    zum einen zu Sicherheitszwecken eingesetzt
                                    werden, z. B. um eine Überlastung der Server
                                    zu vermeiden (insbesondere im Fall von
                                    missbräuchlichen Angriffen, sogenannten
                                    DDoS-Attacken), und zum anderen, um die
                                    Auslastung der Server und ihre Stabilität
                                    sicherzustellen;{' '}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong>{' '}
                                        Berechtigte Interessen (Art. 6 Abs. 1 S.
                                        1 lit. f) DSGVO).{' '}
                                    </span>
                                    <strong>Löschung von Daten:</strong>{' '}
                                    Logfile-Informationen werden für die Dauer
                                    von maximal 30 Tagen gespeichert und danach
                                    gelöscht oder anonymisiert. Daten, deren
                                    weitere Aufbewahrung zu Beweiszwecken
                                    erforderlich ist, sind bis zur endgültigen
                                    Klärung des jeweiligen Vorfalls von der
                                    Löschung ausgenommen.
                                </li>
                                <li>
                                    <strong>Host Europe: </strong>Leistungen auf
                                    dem Gebiet der Bereitstellung von
                                    informationstechnischer Infrastruktur und
                                    verbundenen Dienstleistungen (z. B.
                                    Speicherplatz und/oder Rechenkapazitäten);{' '}
                                    <strong>Dienstanbieter:</strong> Host Europe
                                    GmbH, Hansestrasse 111, 51149 Köln,
                                    Deutschland;{' '}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong>{' '}
                                        Berechtigte Interessen (Art. 6 Abs. 1 S.
                                        1 lit. f) DSGVO);{' '}
                                    </span>
                                    <strong>Website:</strong>{' '}
                                    <a
                                        href="https://www.hosteurope.de"
                                        target="_blank"
                                    >
                                        https://www.hosteurope.de
                                    </a>
                                    ; <strong>Datenschutzerklärung:</strong>{' '}
                                    <a
                                        href="http://www.hosteurope.de/AGB/Datenschutzerklaerung/"
                                        target="_blank"
                                    >
                                        http://www.hosteurope.de/AGB/Datenschutzerklaerung/
                                    </a>
                                    .{' '}
                                    <strong>
                                        Auftragsverarbeitungsvertrag:
                                    </strong>{' '}
                                    <a
                                        href="https://www.hosteurope.de/Dokumente/"
                                        target="_blank"
                                    >
                                        https://www.hosteurope.de/Dokumente/
                                    </a>
                                    .
                                </li>
                            </ul>
                            <h2 id="m134">Einsatz von Cookies</h2>
                            <p>
                                Unter dem Begriff „Cookies" werden Funktionen,
                                die Informationen auf Endgeräten der Nutzer
                                speichern und aus ihnen auslesen, verstanden.
                                Cookies können ferner in Bezug auf
                                unterschiedliche Anliegen Einsatz finden, etwa
                                zu Zwecken der Funktionsfähigkeit, der
                                Sicherheit und des Komforts von Onlineangeboten
                                sowie der Erstellung von Analysen der
                                Besucherströme. Wir verwenden Cookies gemäß den
                                gesetzlichen Vorschriften. Dazu holen wir, wenn
                                erforderlich, vorab die Zustimmung der Nutzer
                                ein. Ist eine Zustimmung nicht notwendig, setzen
                                wir auf unsere berechtigten Interessen. Dies
                                gilt, wenn das Speichern und Auslesen von
                                Informationen unerlässlich ist, um ausdrücklich
                                angeforderte Inhalte und Funktionen
                                bereitstellen zu können. Dazu zählen etwa die
                                Speicherung von Einstellungen sowie die
                                Sicherstellung der Funktionalität und Sicherheit
                                unseres Onlineangebots. Die Einwilligung kann
                                jederzeit widerrufen werden. Wir informieren
                                klar über deren Umfang und welche Cookies
                                genutzt werden.
                            </p>
                            <p>
                                <strong>
                                    Hinweise zu datenschutzrechtlichen
                                    Rechtsgrundlagen:{' '}
                                </strong>
                                Ob wir personenbezogene Daten mithilfe von
                                Cookies verarbeiten, hängt von einer
                                Einwilligung ab. Liegt eine Einwilligung vor,
                                dient sie als Rechtsgrundlage. Ohne Einwilligung
                                stützen wir uns auf unsere berechtigten
                                Interessen, die vorstehend in diesem Abschnitt
                                und im Kontext der jeweiligen Dienste und
                                Verfahren erläutert sind.
                            </p>
                            <p>
                                <strong>Speicherdauer: </strong>Im Hinblick auf
                                die Speicherdauer werden die folgenden Arten von
                                Cookies unterschieden:
                            </p>
                            <ul>
                                <li>
                                    <strong>
                                        Temporäre Cookies (auch: Session- oder
                                        Sitzungscookies):
                                    </strong>{' '}
                                    Temporäre Cookies werden spätestens
                                    gelöscht, nachdem ein Nutzer ein
                                    Onlineangebot verlassen und sein Endgerät
                                    (z. B. Browser oder mobile Applikation)
                                    geschlossen hat.
                                </li>
                                <li>
                                    <strong>Permanente Cookies:</strong>{' '}
                                    Permanente Cookies bleiben auch nach dem
                                    Schließen des Endgeräts gespeichert. So
                                    können beispielsweise der Log-in-Status
                                    gespeichert und bevorzugte Inhalte direkt
                                    angezeigt werden, wenn der Nutzer eine
                                    Website erneut besucht. Ebenso können die
                                    mithilfe von Cookies erhobenen Nutzerdaten
                                    zur Reichweitenmessung Verwendung finden.
                                    Sofern wir Nutzern keine expliziten Angaben
                                    zur Art und Speicherdauer von Cookies
                                    mitteilen (z. B. im Rahmen der Einholung der
                                    Einwilligung), sollten sie davon ausgehen,
                                    dass diese permanent sind und die
                                    Speicherdauer bis zu zwei Jahre betragen
                                    kann.
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    Allgemeine Hinweise zum Widerruf und
                                    Widerspruch (Opt-out):
                                </strong>
                                Nutzer können die von ihnen abgegebenen
                                Einwilligungen jederzeit widerrufen und zudem
                                einen Widerspruch gegen die Verarbeitung
                                entsprechend den gesetzlichen Vorgaben, auch
                                mittels der Privatsphäre-Einstellungen ihres
                                Browsers, erklären.
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Verarbeitete Datenarten:</strong>{' '}
                                    Meta-, Kommunikations- und Verfahrensdaten
                                    (z. B. IP-Adressen, Zeitangaben,
                                    Identifikationsnummern, beteiligte
                                    Personen).
                                </li>
                                <li>
                                    <strong>Betroffene Personen:</strong> Nutzer
                                    (z. B. Webseitenbesucher, Nutzer von
                                    Onlinediensten).
                                </li>
                                <li className="">
                                    <strong>Rechtsgrundlagen:</strong>{' '}
                                    Berechtigte Interessen (Art. 6 Abs. 1 S. 1
                                    lit. f) DSGVO). Einwilligung (Art. 6 Abs. 1
                                    S. 1 lit. a) DSGVO).
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    Weitere Hinweise zu Verarbeitungsprozessen,
                                    Verfahren und Diensten:
                                </strong>
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>
                                        Verarbeitung von Cookie-Daten auf
                                        Grundlage einer Einwilligung:{' '}
                                    </strong>
                                    Wir setzen eine
                                    Einwilligungs-Management-Lösung ein, bei der
                                    die Einwilligung der Nutzer zur Verwendung
                                    von Cookies oder zu den im Rahmen der
                                    Einwilligungs-Management-Lösung genannten
                                    Verfahren und Anbietern eingeholt wird.
                                    Dieses Verfahren dient der Einholung,
                                    Protokollierung, Verwaltung und dem Widerruf
                                    von Einwilligungen, insbesondere bezogen auf
                                    den Einsatz von Cookies und vergleichbaren
                                    Technologien, die zur Speicherung, zum
                                    Auslesen und zur Verarbeitung von
                                    Informationen auf den Endgeräten der Nutzer
                                    eingesetzt werden. Im Rahmen dieses
                                    Verfahrens werden die Einwilligungen der
                                    Nutzer für die Nutzung von Cookies und die
                                    damit verbundenen Verarbeitungen von
                                    Informationen, einschließlich der im
                                    Einwilligungs-Management-Verfahren genannten
                                    spezifischen Verarbeitungen und Anbieter,
                                    eingeholt. Die Nutzer haben zudem die
                                    Möglichkeit, ihre Einwilligungen zu
                                    verwalten und zu widerrufen. Die
                                    Einwilligungserklärungen werden gespeichert,
                                    um eine erneute Abfrage zu vermeiden und den
                                    Nachweis der Einwilligung gemäß der
                                    gesetzlichen Anforderungen führen zu können.
                                    Die Speicherung erfolgt serverseitig
                                    und/oder in einem Cookie (sogenanntes
                                    Opt-In-Cookie) oder mittels vergleichbarer
                                    Technologien, um die Einwilligung einem
                                    spezifischen Nutzer oder dessen Gerät
                                    zuordnen zu können. Sofern keine
                                    spezifischen Angaben zu den Anbietern von
                                    Einwilligungs-Management-Diensten vorliegen,
                                    gelten folgende allgemeine Hinweise: Die
                                    Dauer der Speicherung der Einwilligung
                                    beträgt bis zu zwei Jahre. Dabei wird ein
                                    pseudonymer Nutzer-Identifikator erstellt,
                                    der zusammen mit dem Zeitpunkt der
                                    Einwilligung, den Angaben zum Umfang der
                                    Einwilligung (z. B. betreffende Kategorien
                                    von Cookies und/oder Diensteanbieter) sowie
                                    Informationen über den Browser, das System
                                    und das verwendete Endgerät gespeichert
                                    wird;{' '}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong>{' '}
                                        Einwilligung (Art. 6 Abs. 1 S. 1 lit. a)
                                        DSGVO).
                                    </span>
                                </li>
                            </ul>
                            <h2 id="m182">Kontakt- und Anfrageverwaltung</h2>
                            <p>
                                Bei der Kontaktaufnahme mit uns (z. B. per Post,
                                Kontaktformular, E-Mail, Telefon oder via
                                soziale Medien) sowie im Rahmen bestehender
                                Nutzer- und Geschäftsbeziehungen werden die
                                Angaben der anfragenden Personen verarbeitet,
                                soweit dies zur Beantwortung der Kontaktanfragen
                                und etwaiger angefragter Maßnahmen erforderlich
                                ist.
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Verarbeitete Datenarten:</strong>{' '}
                                    Bestandsdaten (z. B. der vollständige Name,
                                    Wohnadresse, Kontaktinformationen,
                                    Kundennummer, etc.); Kontaktdaten (z. B.
                                    Post- und E-Mail-Adressen oder
                                    Telefonnummern); Inhaltsdaten (z. B.
                                    textliche oder bildliche Nachrichten und
                                    Beiträge sowie die sie betreffenden
                                    Informationen, wie z. B. Angaben zur
                                    Autorenschaft oder Zeitpunkt der
                                    Erstellung); Nutzungsdaten (z. B.
                                    Seitenaufrufe und Verweildauer, Klickpfade,
                                    Nutzungsintensität und -frequenz, verwendete
                                    Gerätetypen und Betriebssysteme,
                                    Interaktionen mit Inhalten und Funktionen).
                                    Meta-, Kommunikations- und Verfahrensdaten
                                    (z. B. IP-Adressen, Zeitangaben,
                                    Identifikationsnummern, beteiligte
                                    Personen).
                                </li>
                                <li>
                                    <strong>Betroffene Personen:</strong>{' '}
                                    Kommunikationspartner.
                                </li>
                                <li>
                                    <strong>Zwecke der Verarbeitung:</strong>{' '}
                                    Kommunikation; Organisations- und
                                    Verwaltungsverfahren; Feedback (z. B.
                                    Sammeln von Feedback via Online-Formular).
                                    Bereitstellung unseres Onlineangebotes und
                                    Nutzerfreundlichkeit.
                                </li>
                                <li>
                                    <strong>Aufbewahrung und Löschung:</strong>{' '}
                                    Löschung entsprechend Angaben im Abschnitt
                                    "Allgemeine Informationen zur
                                    Datenspeicherung und Löschung".
                                </li>
                                <li className="">
                                    <strong>Rechtsgrundlagen:</strong>{' '}
                                    Berechtigte Interessen (Art. 6 Abs. 1 S. 1
                                    lit. f) DSGVO). Vertragserfüllung und
                                    vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1
                                    lit. b) DSGVO).
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    Weitere Hinweise zu Verarbeitungsprozessen,
                                    Verfahren und Diensten:
                                </strong>
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Kontaktformular: </strong>Bei
                                    Kontaktaufnahme über unser Kontaktformular,
                                    per E-Mail oder anderen Kommunikationswegen,
                                    verarbeiten wir die uns übermittelten
                                    personenbezogenen Daten zur Beantwortung und
                                    Bearbeitung des jeweiligen Anliegens. Dies
                                    umfasst in der Regel Angaben wie Name,
                                    Kontaktinformationen und gegebenenfalls
                                    weitere Informationen, die uns mitgeteilt
                                    werden und zur angemessenen Bearbeitung
                                    erforderlich sind. Wir nutzen diese Daten
                                    ausschließlich für den angegebenen Zweck der
                                    Kontaktaufnahme und Kommunikation;{' '}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong>{' '}
                                        Vertragserfüllung und vorvertragliche
                                        Anfragen (Art. 6 Abs. 1 S. 1 lit. b)
                                        DSGVO), Berechtigte Interessen (Art. 6
                                        Abs. 1 S. 1 lit. f) DSGVO).
                                    </span>
                                </li>
                            </ul>
                            <h2 id="m17">
                                Newsletter und elektronische Benachrichtigungen
                            </h2>
                            <p>
                                Wir versenden Newsletter, E-Mails und weitere
                                elektronische Benachrichtigungen (nachfolgend
                                „Newsletter") ausschließlich mit der
                                Einwilligung der Empfänger oder aufgrund einer
                                gesetzlichen Grundlage. Sofern im Rahmen einer
                                Anmeldung zum Newsletter dessen Inhalte genannt
                                werden, sind diese Inhalte für die Einwilligung
                                der Nutzer maßgeblich. Für die Anmeldung zu
                                unserem Newsletter ist normalerweise die Angabe
                                Ihrer E-Mail-Adresse ausreichend. Um Ihnen
                                jedoch einen personalisierten Service bieten zu
                                können, bitten wir gegebenenfalls um die Angabe
                                Ihres Namens für eine persönliche Ansprache im
                                Newsletter oder um weitere Informationen, falls
                                diese für den Zweck des Newsletters notwendig
                                sind.
                            </p>
                            <p>
                                Löschung und Einschränkung der Verarbeitung: Wir
                                können die ausgetragenen E-Mail-Adressen bis zu
                                drei Jahren auf Grundlage unserer berechtigten
                                Interessen speichern, bevor wir sie löschen, um
                                eine ehemals gegebene Einwilligung nachweisen zu
                                können. Die Verarbeitung dieser Daten wird auf
                                den Zweck einer potenziellen Abwehr von
                                Ansprüchen beschränkt. Ein individueller
                                Löschungsantrag ist jederzeit möglich, sofern
                                zugleich das ehemalige Bestehen einer
                                Einwilligung bestätigt wird. Im Fall von
                                Pflichten zur dauerhaften Beachtung von
                                Widersprüchen behalten wir uns die Speicherung
                                der E-Mail-Adresse alleine zu diesem Zweck in
                                einer Sperrliste (sogenannte „Blocklist") vor.
                            </p>
                            <p>
                                Die Protokollierung des Anmeldeverfahrens
                                erfolgt auf Grundlage unserer berechtigten
                                Interessen zum Zweck des Nachweises seines
                                ordnungsgemäßen Ablaufs. Soweit wir einen
                                Dienstleister mit dem Versand von E-Mails
                                beauftragen, erfolgt dies auf Grundlage unserer
                                berechtigten Interessen an einem effizienten und
                                sicheren Versandsystem.
                            </p>
                            <strong>Inhalte:</strong>{' '}
                            <p>
                                Informationen zu uns, unseren Leistungen,
                                Aktionen und Angeboten.
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Verarbeitete Datenarten:</strong>{' '}
                                    Bestandsdaten (z. B. der vollständige Name,
                                    Wohnadresse, Kontaktinformationen,
                                    Kundennummer, etc.); Kontaktdaten (z. B.
                                    Post- und E-Mail-Adressen oder
                                    Telefonnummern); Meta-, Kommunikations- und
                                    Verfahrensdaten (z. B. IP-Adressen,
                                    Zeitangaben, Identifikationsnummern,
                                    beteiligte Personen). Nutzungsdaten (z. B.
                                    Seitenaufrufe und Verweildauer, Klickpfade,
                                    Nutzungsintensität und -frequenz, verwendete
                                    Gerätetypen und Betriebssysteme,
                                    Interaktionen mit Inhalten und Funktionen).
                                </li>
                                <li>
                                    <strong>Betroffene Personen:</strong>{' '}
                                    Kommunikationspartner. Nutzer (z. B.
                                    Webseitenbesucher, Nutzer von
                                    Onlinediensten).
                                </li>
                                <li>
                                    <strong>Zwecke der Verarbeitung:</strong>{' '}
                                    Direktmarketing (z. B. per E-Mail oder
                                    postalisch). Erbringung vertraglicher
                                    Leistungen und Erfüllung vertraglicher
                                    Pflichten.
                                </li>
                                <li className="">
                                    <strong>Rechtsgrundlagen:</strong>{' '}
                                    Einwilligung (Art. 6 Abs. 1 S. 1 lit. a)
                                    DSGVO).
                                </li>
                                <li>
                                    <strong>
                                        Widerspruchsmöglichkeit (Opt-Out):{' '}
                                    </strong>
                                    Sie können den Empfang unseres Newsletters
                                    jederzeit kündigen, d. .h. Ihre
                                    Einwilligungen widerrufen, bzw. dem weiteren
                                    Empfang widersprechen. Einen Link zur
                                    Kündigung des Newsletters finden Sie
                                    entweder am Ende eines jeden Newsletters
                                    oder können sonst eine der oben angegebenen
                                    Kontaktmöglichkeiten, vorzugswürdig E-Mail,
                                    hierzu nutzen.
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    Weitere Hinweise zu Verarbeitungsprozessen,
                                    Verfahren und Diensten:
                                </strong>
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>
                                        Voraussetzung der Inanspruchnahme
                                        kostenloser Leistungen:{' '}
                                    </strong>
                                    Die Einwilligungen in den Versand von
                                    Mailings kann als Voraussetzung zur
                                    Inanspruchnahme kostenloser Leistungen
                                    (z. B. Zugang zu bestimmten Inhalten oder
                                    Teilnahme an bestimmten Aktionen) abhängig
                                    gemacht werden. Sofern die Nutzer die
                                    kostenlose Leistung in Anspruch nehmen
                                    möchten, ohne sich zum Newsletter
                                    anzumelden, bitten wir Sie um eine
                                    Kontaktaufnahme.
                                </li>
                                <li>
                                    <strong>Versand via SMS: </strong>Die
                                    elektronischen Benachrichtigungen können
                                    ebenfalls als SMS-Textnachrichten versendet
                                    werden (bzw. werden ausschließlich via SMS
                                    versendet, falls die Versandberechtigung,
                                    z. B. eine Einwilligung, nur den Versand via
                                    SMS umfasst);{' '}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong>{' '}
                                        Einwilligung (Art. 6 Abs. 1 S. 1 lit. a)
                                        DSGVO).
                                    </span>
                                </li>
                            </ul>
                            <h2 id="m638">
                                Werbliche Kommunikation via E-Mail, Post, Fax
                                oder Telefon
                            </h2>
                            <p>
                                Wir verarbeiten personenbezogene Daten zu
                                Zwecken der werblichen Kommunikation, die über
                                diverse Kanäle, wie z. B. E-Mail, Telefon, Post
                                oder Fax, entsprechend den gesetzlichen Vorgaben
                                erfolgen kann.
                            </p>
                            <p>
                                Die Empfänger haben das Recht, erteilte
                                Einwilligungen jederzeit zu widerrufen oder der
                                werblichen Kommunikation jederzeit zu
                                widersprechen.
                            </p>
                            <p>
                                Nach Widerruf oder Widerspruch speichern wir die
                                zum Nachweis der bisherigen Berechtigung
                                erforderlichen Daten zur Kontaktaufnahme oder
                                Zusendung bis zu drei Jahre nach Ablauf des
                                Jahres des Widerrufs oder Widerspruchs auf der
                                Grundlage unserer berechtigten Interessen. Die
                                Verarbeitung dieser Daten ist auf den Zweck
                                einer möglichen Abwehr von Ansprüchen
                                beschränkt. Auf der Grundlage des berechtigten
                                Interesses, den Widerruf bzw. Widerspruch der
                                Nutzer dauerhaft zu beachten, speichern wir
                                ferner die zur Vermeidung einer erneuten
                                Kontaktaufnahme erforderlichen Daten (z. B. je
                                nach Kommunikationskanal die E-Mail-Adresse,
                                Telefonnummer, Name).
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Verarbeitete Datenarten:</strong>{' '}
                                    Bestandsdaten (z. B. der vollständige Name,
                                    Wohnadresse, Kontaktinformationen,
                                    Kundennummer, etc.); Kontaktdaten (z. B.
                                    Post- und E-Mail-Adressen oder
                                    Telefonnummern). Inhaltsdaten (z. B.
                                    textliche oder bildliche Nachrichten und
                                    Beiträge sowie die sie betreffenden
                                    Informationen, wie z. B. Angaben zur
                                    Autorenschaft oder Zeitpunkt der
                                    Erstellung).
                                </li>
                                <li>
                                    <strong>Betroffene Personen:</strong>{' '}
                                    Kommunikationspartner.
                                </li>
                                <li>
                                    <strong>Zwecke der Verarbeitung:</strong>{' '}
                                    Direktmarketing (z. B. per E-Mail oder
                                    postalisch); Marketing. Absatzförderung.
                                </li>
                                <li>
                                    <strong>Aufbewahrung und Löschung:</strong>{' '}
                                    Löschung entsprechend Angaben im Abschnitt
                                    "Allgemeine Informationen zur
                                    Datenspeicherung und Löschung".
                                </li>
                                <li className="">
                                    <strong>Rechtsgrundlagen:</strong>{' '}
                                    Einwilligung (Art. 6 Abs. 1 S. 1 lit. a)
                                    DSGVO). Berechtigte Interessen (Art. 6 Abs.
                                    1 S. 1 lit. f) DSGVO).
                                </li>
                            </ul>
                            <h2 id="m263">
                                Webanalyse, Monitoring und Optimierung
                            </h2>
                            <p>
                                Die Webanalyse (auch als „Reichweitenmessung"
                                bezeichnet) dient der Auswertung der
                                Besucherströme unseres Onlineangebots und kann
                                Verhalten, Interessen oder demografische
                                Informationen zu den Besuchern, wie
                                beispielsweise Alter oder Geschlecht, als
                                pseudonyme Werte umfassen. Mithilfe der
                                Reichweitenanalyse können wir zum Beispiel
                                erkennen, zu welcher Zeit unser Onlineangebot
                                oder dessen Funktionen beziehungsweise Inhalte
                                am häufigsten genutzt werden, oder zur
                                Wiederverwendung einladen. Ebenso ist es uns
                                möglich, nachzuvollziehen, welche Bereiche der
                                Optimierung bedürfen.{' '}
                            </p>
                            <p>
                                Neben der Webanalyse können wir auch
                                Testverfahren einsetzen, um etwa
                                unterschiedliche Versionen unseres
                                Onlineangebots oder seiner Bestandteile zu
                                testen und zu optimieren.
                            </p>
                            <p>
                                Sofern nachfolgend nicht anders angegeben,
                                können zu diesen Zwecken Profile, also zu einem
                                Nutzungsvorgang zusammengefasste Daten, angelegt
                                und Informationen in einem Browser bzw. in einem
                                Endgerät gespeichert und dann ausgelesen werden.
                                Zu den erhobenen Angaben gehören insbesondere
                                besuchte Websites und dort genutzte Elemente
                                sowie technische Auskünfte, wie etwa der
                                verwendete Browser, das benutzte Computersystem
                                sowie Angaben zu Nutzungszeiten. Sofern sich
                                Nutzer in die Erhebung ihrer Standortdaten uns
                                gegenüber oder gegenüber den Anbietern der von
                                uns eingesetzten Dienste einverstanden erklärt
                                haben, ist auch die Verarbeitung von
                                Standortdaten möglich.
                            </p>
                            <p>
                                Darüber hinaus werden die IP-Adressen der Nutzer
                                gespeichert. Jedoch nutzen wir ein
                                IP-Masking-Verfahren (d. h. Pseudonymisierung
                                durch Kürzung der IP-Adresse) zum Schutz der
                                Nutzer. Generell werden die im Rahmen von
                                Webanalyse, A/B-Testings und Optimierung keine
                                Klardaten der Nutzer (wie z. B. E-Mail-Adressen
                                oder Namen) gespeichert, sondern Pseudonyme. Das
                                heißt, wir als auch die Anbieter der
                                eingesetzten Software kennen nicht die
                                tatsächliche Identität der Nutzer, sondern nur
                                die zum Zweck der jeweiligen Verfahren in deren
                                Profilen gespeicherten Angaben.
                            </p>
                            <p>
                                Hinweise zu Rechtsgrundlagen: Sofern wir die
                                Nutzer um deren Einwilligung in den Einsatz der
                                Drittanbieter bitten, stellt die Rechtsgrundlage
                                der Datenverarbeitung die Einwilligung dar.
                                Ansonsten werden die Nutzerdaten auf Grundlage
                                unserer berechtigten Interessen (d. h. Interesse
                                an effizienten, wirtschaftlichen und
                                empfängerfreundlichen Leistungen) verarbeitet.
                                In diesem Zusammenhang möchten wir Sie auch auf
                                die Informationen zur Verwendung von Cookies in
                                dieser Datenschutzerklärung hinweisen.
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Verarbeitete Datenarten:</strong>{' '}
                                    Nutzungsdaten (z. B. Seitenaufrufe und
                                    Verweildauer, Klickpfade, Nutzungsintensität
                                    und -frequenz, verwendete Gerätetypen und
                                    Betriebssysteme, Interaktionen mit Inhalten
                                    und Funktionen). Meta-, Kommunikations- und
                                    Verfahrensdaten (z. B. IP-Adressen,
                                    Zeitangaben, Identifikationsnummern,
                                    beteiligte Personen).
                                </li>
                                <li>
                                    <strong>Betroffene Personen:</strong> Nutzer
                                    (z. B. Webseitenbesucher, Nutzer von
                                    Onlinediensten).
                                </li>
                                <li>
                                    <strong>Zwecke der Verarbeitung:</strong>{' '}
                                    Reichweitenmessung (z. B.
                                    Zugriffsstatistiken, Erkennung
                                    wiederkehrender Besucher); Profile mit
                                    nutzerbezogenen Informationen (Erstellen von
                                    Nutzerprofilen). Bereitstellung unseres
                                    Onlineangebotes und Nutzerfreundlichkeit.
                                </li>
                                <li>
                                    <strong>Aufbewahrung und Löschung:</strong>{' '}
                                    Löschung entsprechend Angaben im Abschnitt
                                    "Allgemeine Informationen zur
                                    Datenspeicherung und Löschung". Speicherung
                                    von Cookies von bis zu 2 Jahren (Sofern
                                    nicht anders angegeben, können Cookies und
                                    ähnliche Speichermethoden für einen Zeitraum
                                    von zwei Jahren auf den Geräten der Nutzer
                                    gespeichert werden.).
                                </li>
                                <li>
                                    <strong>Sicherheitsmaßnahmen:</strong>{' '}
                                    IP-Masking (Pseudonymisierung der
                                    IP-Adresse).
                                </li>
                                <li className="">
                                    <strong>Rechtsgrundlagen:</strong>{' '}
                                    Einwilligung (Art. 6 Abs. 1 S. 1 lit. a)
                                    DSGVO). Berechtigte Interessen (Art. 6 Abs.
                                    1 S. 1 lit. f) DSGVO).
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    Weitere Hinweise zu Verarbeitungsprozessen,
                                    Verfahren und Diensten:
                                </strong>
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Google Analytics: </strong>Wir
                                    verwenden Google Analytics zur Messung und
                                    Analyse der Nutzung unseres Onlineangebotes
                                    auf der Grundlage einer pseudonymen
                                    Nutzeridentifikationsnummer. Diese
                                    Identifikationsnummer enthält keine
                                    eindeutigen Daten, wie Namen oder
                                    E-Mail-Adressen. Sie dient dazu,
                                    Analyseinformationen einem Endgerät
                                    zuzuordnen, um zu erkennen, welche Inhalte
                                    die Nutzer innerhalb eines oder
                                    verschiedener Nutzungsvorgänge aufgerufen
                                    haben, welche Suchbegriffe sie verwendet
                                    haben, diese erneut aufgerufen haben oder
                                    mit unserem Onlineangebot interagiert haben.
                                    Ebenso werden der Zeitpunkt der Nutzung und
                                    deren Dauer gespeichert, sowie die Quellen
                                    der Nutzer, die auf unser Onlineangebot
                                    verweisen und technische Aspekte ihrer
                                    Endgeräte und Browser.
                                    <br />
                                    Dabei werden pseudonyme Profile von Nutzern
                                    mit Informationen aus der Nutzung
                                    verschiedener Geräte erstellt, wobei Cookies
                                    eingesetzt werden können. Google Analytics
                                    protokolliert und speichert keine
                                    individuellen IP-Adressen für EU-Nutzer.
                                    Analytics stellt jedoch grobe geografische
                                    Standortdaten bereit, indem es die folgenden
                                    Metadaten von IP-Adressen ableitet: Stadt
                                    (und der abgeleitete Breiten- und Längengrad
                                    der Stadt), Kontinent, Land, Region,
                                    Subkontinent (und ID-basierte Gegenstücke).
                                    Beim EU-Datenverkehr werden die
                                    IP-Adressdaten ausschließlich für diese
                                    Ableitung von Geolokalisierungsdaten
                                    verwendet, bevor sie sofort gelöscht werden.
                                    Sie werden nicht protokolliert, sind nicht
                                    zugänglich und werden nicht für weitere
                                    Verwendungszwecke genutzt. Wenn Google
                                    Analytics Messdaten sammelt, werden alle
                                    IP-Abfragen auf EU-basierten Servern
                                    durchgeführt, bevor der Verkehr zur
                                    Verarbeitung an Analytics-Server
                                    weitergeleitet wird;{' '}
                                    <strong>Dienstanbieter:</strong> Google
                                    Ireland Limited, Gordon House, Barrow
                                    Street, Dublin 4, Irland;{' '}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong>{' '}
                                        Einwilligung (Art. 6 Abs. 1 S. 1 lit. a)
                                        DSGVO);{' '}
                                    </span>
                                    <strong>Website:</strong>{' '}
                                    <a
                                        href="https://marketingplatform.google.com/intl/de/about/analytics/"
                                        target="_blank"
                                    >
                                        https://marketingplatform.google.com/intl/de/about/analytics/
                                    </a>
                                    ; <strong>Sicherheitsmaßnahmen:</strong>{' '}
                                    IP-Masking (Pseudonymisierung der
                                    IP-Adresse);{' '}
                                    <strong>Datenschutzerklärung:</strong>{' '}
                                    <a
                                        href="https://policies.google.com/privacy"
                                        target="_blank"
                                    >
                                        https://policies.google.com/privacy
                                    </a>
                                    ;{' '}
                                    <strong>
                                        Auftragsverarbeitungsvertrag:
                                    </strong>{' '}
                                    <a
                                        href="https://business.safety.google/adsprocessorterms/"
                                        target="_blank"
                                    >
                                        https://business.safety.google/adsprocessorterms/
                                    </a>
                                    ;{' '}
                                    <strong>
                                        Widerspruchsmöglichkeit (Opt-Out):
                                    </strong>{' '}
                                    Opt-Out-Plugin:{' '}
                                    <a
                                        href="https://tools.google.com/dlpage/gaoptout?hl=de"
                                        target="_blank"
                                    >
                                        https://tools.google.com/dlpage/gaoptout?hl=de
                                    </a>
                                    , Einstellungen für die Darstellung von
                                    Werbeeinblendungen:{' '}
                                    <a
                                        href="https://myadcenter.google.com/personalizationoff"
                                        target="_blank"
                                    >
                                        https://myadcenter.google.com/personalizationoff
                                    </a>
                                    . <strong>Weitere Informationen:</strong>{' '}
                                    <a
                                        href="https://business.safety.google/adsservices/"
                                        target="_blank"
                                    >
                                        https://business.safety.google/adsservices/
                                    </a>{' '}
                                    (Arten der Verarbeitung sowie der
                                    verarbeiteten Daten).
                                </li>
                                <li>
                                    <strong>Matomo: </strong>Bei Matomo handelt
                                    es sich um eine Software, die zu Zwecken der
                                    Webanalyse und Reichweitenmessung eingesetzt
                                    wird. Im Rahmen der Nutzung von Matomo
                                    werden Cookies erzeugt und auf dem Endgerät
                                    der Nutzer gespeichert. Die im Rahmen der
                                    Nutzung von Matomo erhobenen Daten der
                                    Nutzer werden nur von uns verarbeitet und
                                    nicht mit Dritten geteilt. Die Cookies
                                    werden für einen maximalen Zeitraum von 13
                                    Monaten gespeichert:{' '}
                                    <a
                                        href="https://matomo.org/faq/general/faq_146/"
                                        target="_blank"
                                    >
                                        https://matomo.org/faq/general/faq_146/
                                    </a>
                                    ;{' '}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong>{' '}
                                        Einwilligung (Art. 6 Abs. 1 S. 1 lit. a)
                                        DSGVO).{' '}
                                    </span>
                                    <strong>Löschung von Daten:</strong> Die
                                    Cookies haben eine Speicherdauer von maximal
                                    13 Monaten.
                                </li>
                            </ul>
                            <h2 id="m264">Onlinemarketing</h2>
                            <p>
                                Wir verarbeiten personenbezogene Daten zum Zweck
                                des Onlinemarketings, worunter insbesondere die
                                Vermarktung von Werbeflächen oder die
                                Darstellung von werbenden und sonstigen Inhalten
                                (zusammenfassend als „Inhalte" bezeichnet)
                                anhand potenzieller Interessen der Nutzer sowie
                                die Messung ihrer Effektivität fallen können.
                            </p>
                            <p>
                                Zu diesen Zwecken werden sogenannte
                                Nutzerprofile angelegt und in einer Datei (der
                                sogenannte „Cookie") gespeichert oder ähnliche
                                Verfahren genutzt, mittels derer die für die
                                Darstellung der vorgenannten Inhalte relevanten
                                Angaben zum Nutzer gespeichert werden. Hierzu
                                können beispielsweise betrachtete Inhalte,
                                besuchte Websites, genutzte Onlinenetzwerke,
                                aber auch Kommunikationspartner und technische
                                Angaben gehören, wie etwa der verwendete
                                Browser, das benutzte Computersystem sowie
                                Auskünfte zu Nutzungszeiten und genutzten
                                Funktionen. Sofern Nutzer in die Erhebung ihrer
                                Standortdaten eingewilligt haben, können auch
                                diese verarbeitet werden.
                            </p>
                            <p>
                                Zudem werden die IP-Adressen der Nutzer
                                gespeichert. Jedoch nutzen wir zur Verfügung
                                stehende IP-Masking-Verfahren (d. h.
                                Pseudonymisierung durch Kürzung der IP-Adresse)
                                zum Nutzerschutz. Generell werden im Rahmen des
                                Onlinemarketingverfahrens keine Klardaten der
                                Nutzer (wie z. B. E-Mail-Adressen oder Namen)
                                gespeichert, sondern Pseudonyme. Das heißt, wir
                                als auch die Anbieter der
                                Onlinemarketingverfahren kennen nicht die
                                tatsächliche Nutzeridentität, sondern nur die in
                                deren Profilen gespeicherten Angaben.
                            </p>
                            <p>
                                Die Aussagen in den Profilen werden im Regelfall
                                in den Cookies oder mittels ähnlicher Verfahren
                                gespeichert. Diese Cookies können später
                                generell auch auf anderen Websites, die dasselbe
                                Onlinemarketingverfahren einsetzen, ausgelesen
                                und zum Zweck der Darstellung von Inhalten
                                analysiert sowie mit weiteren Daten ergänzt und
                                auf dem Server des
                                Onlinemarketingverfahrensanbieters gespeichert
                                werden.
                            </p>
                            <p>
                                Ausnahmsweise ist es möglich, Klardaten den
                                Profilen zuzuordnen, vornehmlich dann, wenn die
                                Nutzer zum Beispiel Mitglieder eines sozialen
                                Netzwerks sind, dessen Onlinemarketingverfahren
                                wir einsetzen und das Netzwerk die Nutzerprofile
                                mit den vorgenannten Angaben verbindet. Wir
                                bitten darum, zu beachten, dass Nutzer mit den
                                Anbietern zusätzliche Abreden treffen können,
                                etwa durch Einwilligung im Rahmen der
                                Registrierung.
                            </p>
                            <p>
                                Wir erhalten grundsätzlich nur Zugang zu
                                zusammengefassten Informationen über den Erfolg
                                unserer Werbeanzeigen. Jedoch können wir im
                                Rahmen sogenannter Konversionsmessungen prüfen,
                                welche unserer Onlinemarketingverfahren zu einer
                                sogenannten Konversion geführt haben, d. h.
                                beispielsweise zu einem Vertragsschluss mit uns.
                                Die Konversionsmessung wird alleine zur
                                Erfolgsanalyse unserer Marketingmaßnahmen
                                verwendet.
                            </p>
                            <p>
                                Solange nicht anders angegeben, bitten wir Sie,
                                davon auszugehen, dass eingesetzte Cookies für
                                einen Zeitraum von zwei Jahren gespeichert
                                werden.
                            </p>
                            <p>
                                <strong>Hinweise zu Rechtsgrundlagen:</strong>{' '}
                                Sofern wir die Nutzer um deren Einwilligung in
                                den Einsatz der Drittanbieter bitten, stellt die
                                Rechtsgrundlage der Datenverarbeitung die
                                Erlaubnis dar. Ansonsten werden die Daten der
                                Nutzer auf Grundlage unserer berechtigten
                                Interessen (d. h. Interesse an effizienten,
                                wirtschaftlichen und empfängerfreundlichen
                                Leistungen) verarbeitet. In diesem Zusammenhang
                                möchten wir Sie auch auf die Informationen zur
                                Verwendung von Cookies in dieser
                                Datenschutzerklärung hinweisen.
                            </p>
                            <p>
                                <strong>
                                    Hinweise zum Widerruf und Widerspruch:
                                </strong>{' '}
                            </p>
                            <p>
                                Wir verweisen auf die Datenschutzhinweise der
                                jeweiligen Anbieter und die zu den Anbietern
                                angegebenen Widerspruchsmöglichkeiten (sog.
                                "Opt-Out"). Sofern keine explizite
                                Opt-Out-Möglichkeit angegeben wurde, besteht zum
                                einen die Möglichkeit, dass Sie Cookies in den
                                Einstellungen Ihres Browsers abschalten.
                                Hierdurch können jedoch Funktionen unseres
                                Onlineangebotes eingeschränkt werden. Wir
                                empfehlen daher zusätzlich die folgenden
                                Opt-Out-Möglichkeiten, die zusammenfassend auf
                                jeweilige Gebiete gerichtet angeboten werden:{' '}
                            </p>
                            <p>
                                a) Europa:{' '}
                                <a
                                    href="https://www.youronlinechoices.eu"
                                    target="_blank"
                                >
                                    https://www.youronlinechoices.eu.
                                </a>{' '}
                            </p>
                            <p>
                                b) Kanada:{' '}
                                <a
                                    href="https://www.youradchoices.ca/choices"
                                    target="_blank"
                                >
                                    https://www.youradchoices.ca/choices.
                                </a>{' '}
                            </p>
                            <p>
                                c) USA:{' '}
                                <a
                                    href="https://www.aboutads.info/choices"
                                    target="_blank"
                                >
                                    https://www.aboutads.info/choices.
                                </a>{' '}
                            </p>
                            <p>
                                d) Gebietsübergreifend:{' '}
                                <a
                                    href="https://optout.aboutads.info"
                                    target="_blank"
                                >
                                    https://optout.aboutads.info.
                                </a>
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Verarbeitete Datenarten:</strong>{' '}
                                    Nutzungsdaten (z. B. Seitenaufrufe und
                                    Verweildauer, Klickpfade, Nutzungsintensität
                                    und -frequenz, verwendete Gerätetypen und
                                    Betriebssysteme, Interaktionen mit Inhalten
                                    und Funktionen). Meta-, Kommunikations- und
                                    Verfahrensdaten (z. B. IP-Adressen,
                                    Zeitangaben, Identifikationsnummern,
                                    beteiligte Personen).
                                </li>
                                <li>
                                    <strong>Betroffene Personen:</strong> Nutzer
                                    (z. B. Webseitenbesucher, Nutzer von
                                    Onlinediensten).
                                </li>
                                <li>
                                    <strong>Zwecke der Verarbeitung:</strong>{' '}
                                    Reichweitenmessung (z. B.
                                    Zugriffsstatistiken, Erkennung
                                    wiederkehrender Besucher); Tracking (z. B.
                                    interessens-/verhaltensbezogenes Profiling,
                                    Nutzung von Cookies); Zielgruppenbildung;
                                    Marketing; Profile mit nutzerbezogenen
                                    Informationen (Erstellen von
                                    Nutzerprofilen). Konversionsmessung (Messung
                                    der Effektivität von Marketingmaßnahmen).
                                </li>
                                <li>
                                    <strong>Aufbewahrung und Löschung:</strong>{' '}
                                    Löschung entsprechend Angaben im Abschnitt
                                    "Allgemeine Informationen zur
                                    Datenspeicherung und Löschung". Speicherung
                                    von Cookies von bis zu 2 Jahren (Sofern
                                    nicht anders angegeben, können Cookies und
                                    ähnliche Speichermethoden für einen Zeitraum
                                    von zwei Jahren auf den Geräten der Nutzer
                                    gespeichert werden.).
                                </li>
                                <li>
                                    <strong>Sicherheitsmaßnahmen:</strong>{' '}
                                    IP-Masking (Pseudonymisierung der
                                    IP-Adresse).
                                </li>
                                <li className="">
                                    <strong>Rechtsgrundlagen:</strong>{' '}
                                    Einwilligung (Art. 6 Abs. 1 S. 1 lit. a)
                                    DSGVO). Berechtigte Interessen (Art. 6 Abs.
                                    1 S. 1 lit. f) DSGVO).
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    Weitere Hinweise zu Verarbeitungsprozessen,
                                    Verfahren und Diensten:
                                </strong>
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>
                                        Google Ads und Konversionsmessung:{' '}
                                    </strong>
                                    Online-Marketing-Verfahren zum Zwecke der
                                    Platzierung von Inhalten und Anzeigen
                                    innerhalb des Werbenetzwerks des
                                    Diensteanbieters (z. B. in Suchergebnissen,
                                    in Videos, auf Webseiten usw.), so dass sie
                                    Nutzern angezeigt werden, die ein
                                    mutmaßliches Interesse an den Anzeigen
                                    haben. Darüber hinaus messen wir die
                                    Konversion der Anzeigen, d. h. ob die Nutzer
                                    sie zum Anlass genommen haben, mit den
                                    Anzeigen zu interagieren und die beworbenen
                                    Angebote zu nutzen (sog. Konversionen). Wir
                                    erhalten jedoch nur anonyme Informationen
                                    und keine persönlichen Informationen über
                                    einzelne Nutzer;{' '}
                                    <strong>Dienstanbieter:</strong> Google
                                    Ireland Limited, Gordon House, Barrow
                                    Street, Dublin 4, Irland;{' '}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong>{' '}
                                        Einwilligung (Art. 6 Abs. 1 S. 1 lit. a)
                                        DSGVO), Berechtigte Interessen (Art. 6
                                        Abs. 1 S. 1 lit. f) DSGVO);{' '}
                                    </span>
                                    <strong>Website:</strong>{' '}
                                    <a
                                        href="https://marketingplatform.google.com"
                                        target="_blank"
                                    >
                                        https://marketingplatform.google.com
                                    </a>
                                    ; <strong>Datenschutzerklärung:</strong>{' '}
                                    <a
                                        href="https://policies.google.com/privacy"
                                        target="_blank"
                                    >
                                        https://policies.google.com/privacy
                                    </a>
                                    ; <strong>Weitere Informationen:</strong>{' '}
                                    Arten der Verarbeitung sowie der
                                    verarbeiteten Daten:{' '}
                                    <a
                                        href="https://business.safety.google/adsservices/"
                                        target="_blank"
                                    >
                                        https://business.safety.google/adsservices/
                                    </a>
                                    . Datenverarbeitungsbedingungen zwischen
                                    Verantwortlichen und
                                    Standardvertragsklauseln für
                                    Drittlandtransfers von Daten:{' '}
                                    <a
                                        href="https://business.safety.google/adscontrollerterms"
                                        target="_blank"
                                    >
                                        https://business.safety.google/adscontrollerterms
                                    </a>
                                    .
                                </li>
                                <li>
                                    <strong>
                                        Google Adsense mit personalisierten
                                        Anzeigen:{' '}
                                    </strong>
                                    Wir binden den Dienst Google Adsense ein,
                                    der es ermöglicht, personalisierte Anzeigen
                                    innerhalb unseres Onlineangebots zu
                                    platzieren. Google Adsense analysiert das
                                    Nutzerverhalten und verwendet diese Daten,
                                    um zielgerichtete Werbung auszuspielen, die
                                    auf die Interessen unserer Besucher
                                    abgestimmt ist. Für jede Anzeigenschaltung
                                    oder andere Nutzungsarten dieser Anzeigen
                                    erhalten wir eine finanzielle Vergütung;{' '}
                                    <strong>Dienstanbieter:</strong> Google
                                    Ireland Limited, Gordon House, Barrow
                                    Street, Dublin 4, Irland;{' '}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong>{' '}
                                        Einwilligung (Art. 6 Abs. 1 S. 1 lit. a)
                                        DSGVO);{' '}
                                    </span>
                                    <strong>Website:</strong>{' '}
                                    <a
                                        href="https://marketingplatform.google.com"
                                        target="_blank"
                                    >
                                        https://marketingplatform.google.com
                                    </a>
                                    ; <strong>Datenschutzerklärung:</strong>{' '}
                                    <a
                                        href="https://policies.google.com/privacy"
                                        target="_blank"
                                    >
                                        https://policies.google.com/privacy
                                    </a>
                                    ; <strong>Weitere Informationen:</strong>{' '}
                                    Arten der Verarbeitung sowie der
                                    verarbeiteten Daten:{' '}
                                    <a
                                        href="https://business.safety.google/adsservices/"
                                        target="_blank"
                                    >
                                        https://business.safety.google/adsservices/
                                    </a>
                                    . Datenverarbeitungsbedingungen für Google
                                    Werbeprodukte: Informationen zu den Diensten
                                    Datenverarbeitungsbedingungen zwischen
                                    Verantwortlichen und
                                    Standardvertragsklauseln für
                                    Drittlandtransfers von Daten:{' '}
                                    <a
                                        href="https://business.safety.google/adscontrollerterms"
                                        target="_blank"
                                    >
                                        https://business.safety.google/adscontrollerterms
                                    </a>
                                    .
                                </li>
                                <li>
                                    <strong>
                                        Google Adsense mit
                                        nicht-personalisierten Anzeigen:{' '}
                                    </strong>
                                    Wir nutzen den Dienst Google Adsense, um
                                    nicht-personalisierte Anzeigen in unserem
                                    Onlineangebot zu schalten. Diese Anzeigen
                                    basieren nicht auf dem individuellen
                                    Nutzerverhalten, sondern werden anhand
                                    allgemeiner Merkmale wie dem Inhalt der
                                    Seite oder Ihrer ungefähren geografischen
                                    Lage ausgewählt. Für die Einblendung oder
                                    sonstige Nutzung dieser Anzeigen erhalten
                                    wir eine Vergütung;{' '}
                                    <strong>Dienstanbieter:</strong> Google
                                    Ireland Limited, Gordon House, Barrow
                                    Street, Dublin 4, Irland;{' '}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong>{' '}
                                        Einwilligung (Art. 6 Abs. 1 S. 1 lit. a)
                                        DSGVO);{' '}
                                    </span>
                                    <strong>Website:</strong>{' '}
                                    <a
                                        href="https://marketingplatform.google.com"
                                        target="_blank"
                                    >
                                        https://marketingplatform.google.com
                                    </a>
                                    ; <strong>Datenschutzerklärung:</strong>{' '}
                                    <a
                                        href="https://policies.google.com/privacy"
                                        target="_blank"
                                    >
                                        https://policies.google.com/privacy
                                    </a>
                                    ; <strong>Weitere Informationen:</strong>{' '}
                                    Arten der Verarbeitung sowie der
                                    verarbeiteten Daten:{' '}
                                    <a
                                        href="https://business.safety.google/adsservices/"
                                        target="_blank"
                                    >
                                        https://business.safety.google/adsservices/
                                    </a>
                                    . Datenverarbeitungsbedingungen für Google
                                    Werbeprodukte: Informationen zu den Diensten
                                    Datenverarbeitungsbedingungen zwischen
                                    Verantwortlichen und
                                    Standardvertragsklauseln für
                                    Drittlandtransfers von Daten:{' '}
                                    <a
                                        href="https://business.safety.google/adscontrollerterms"
                                        target="_blank"
                                    >
                                        https://business.safety.google/adscontrollerterms
                                    </a>
                                    .
                                </li>
                                <li>
                                    <strong>LinkedIn Insight Tag: </strong>Code,
                                    der geladen wird, wenn ein Benutzer unser
                                    Onlineangebot besucht und das Verhalten und
                                    die Konversionen des Benutzers verfolgt
                                    sowie in einem Profil speichert (mögliche
                                    Einsatzzwecke: Messung von
                                    Kampagnenleistungen, Optimierung der
                                    Anzeigenauslieferung, Aufbau von
                                    benutzerdefinierten und ähnlichen
                                    Zielgruppen);{' '}
                                    <strong>Dienstanbieter:</strong> LinkedIn
                                    Ireland Unlimited Company, Wilton Place,
                                    Dublin 2, Irland;{' '}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong>{' '}
                                        Einwilligung (Art. 6 Abs. 1 S. 1 lit. a)
                                        DSGVO);{' '}
                                    </span>
                                    <strong>Website:</strong>{' '}
                                    <a
                                        href="https://www.linkedin.com"
                                        target="_blank"
                                    >
                                        https://www.linkedin.com
                                    </a>
                                    ; <strong>Datenschutzerklärung:</strong>{' '}
                                    <a
                                        href="https://www.linkedin.com/legal/privacy-policy"
                                        target="_blank"
                                    >
                                        https://www.linkedin.com/legal/privacy-policy
                                    </a>
                                    , Cookie-Policy:{' '}
                                    <a
                                        href="https://www.linkedin.com/legal/cookie_policy"
                                        target="_blank"
                                    >
                                        https://www.linkedin.com/legal/cookie_policy
                                    </a>
                                    ;{' '}
                                    <strong>
                                        Auftragsverarbeitungsvertrag:
                                    </strong>{' '}
                                    <a
                                        href="https://www.linkedin.com/legal/l/dpa"
                                        target="_blank"
                                    >
                                        https://www.linkedin.com/legal/l/dpa
                                    </a>
                                    .{' '}
                                    <strong>
                                        Widerspruchsmöglichkeit (Opt-Out):
                                    </strong>{' '}
                                    <a
                                        href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
                                        target="_blank"
                                    >
                                        https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out
                                    </a>
                                    .
                                </li>
                            </ul>
                            <h2 id="m15">Änderung und Aktualisierung</h2>
                            <p>
                                Wir bitten Sie, sich regelmäßig über den Inhalt
                                unserer Datenschutzerklärung zu informieren. Wir
                                passen die Datenschutzerklärung an, sobald die
                                Änderungen der von uns durchgeführten
                                Datenverarbeitungen dies erforderlich machen.
                                Wir informieren Sie, sobald durch die Änderungen
                                eine Mitwirkungshandlung Ihrerseits (z. B.
                                Einwilligung) oder eine sonstige individuelle
                                Benachrichtigung erforderlich wird.
                            </p>
                            <p>
                                Sofern wir in dieser Datenschutzerklärung
                                Adressen und Kontaktinformationen von
                                Unternehmen und Organisationen angeben, bitten
                                wir zu beachten, dass die Adressen sich über die
                                Zeit ändern können und bitten die Angaben vor
                                Kontaktaufnahme zu prüfen.
                            </p>
                            <h2 id="m42">Begriffsdefinitionen</h2>
                            <p>
                                In diesem Abschnitt erhalten Sie eine Übersicht
                                über die in dieser Datenschutzerklärung
                                verwendeten Begrifflichkeiten. Soweit die
                                Begrifflichkeiten gesetzlich definiert sind,
                                gelten deren gesetzliche Definitionen. Die
                                nachfolgenden Erläuterungen sollen dagegen vor
                                allem dem Verständnis dienen.
                            </p>
                            <ul className="glossary">
                                <li>
                                    <strong>Bestandsdaten:</strong>{' '}
                                    Bestandsdaten umfassen wesentliche
                                    Informationen, die für die Identifikation
                                    und Verwaltung von Vertragspartnern,
                                    Benutzerkonten, Profilen und ähnlichen
                                    Zuordnungen notwendig sind. Diese Daten
                                    können u.a. persönliche und demografische
                                    Angaben wie Namen, Kontaktinformationen
                                    (Adressen, Telefonnummern, E-Mail-Adressen),
                                    Geburtsdaten und spezifische Identifikatoren
                                    (Benutzer-IDs) beinhalten. Bestandsdaten
                                    bilden die Grundlage für jegliche formelle
                                    Interaktion zwischen Personen und Diensten,
                                    Einrichtungen oder Systemen, indem sie eine
                                    eindeutige Zuordnung und Kommunikation
                                    ermöglichen.{' '}
                                </li>
                                <li>
                                    <strong>Inhaltsdaten:</strong> Inhaltsdaten
                                    umfassen Informationen, die im Zuge der
                                    Erstellung, Bearbeitung und Veröffentlichung
                                    von Inhalten aller Art generiert werden.
                                    Diese Kategorie von Daten kann Texte,
                                    Bilder, Videos, Audiodateien und andere
                                    multimediale Inhalte einschließen, die auf
                                    verschiedenen Plattformen und Medien
                                    veröffentlicht werden. Inhaltsdaten sind
                                    nicht nur auf den eigentlichen Inhalt
                                    beschränkt, sondern beinhalten auch
                                    Metadaten, die Informationen über den Inhalt
                                    selbst liefern, wie Tags, Beschreibungen,
                                    Autoreninformationen und
                                    Veröffentlichungsdaten{' '}
                                </li>
                                <li>
                                    <strong>Kontaktdaten:</strong> Kontaktdaten
                                    sind essentielle Informationen, die die
                                    Kommunikation mit Personen oder
                                    Organisationen ermöglichen. Sie umfassen
                                    u.a. Telefonnummern, postalische Adressen
                                    und E-Mail-Adressen, sowie
                                    Kommunikationsmittel wie soziale
                                    Medien-Handles und
                                    Instant-Messaging-Identifikatoren.{' '}
                                </li>
                                <li>
                                    <strong>Konversionsmessung:</strong> Die
                                    Konversionsmessung (auch als
                                    "Besuchsaktionsauswertung" bezeichnet) ist
                                    ein Verfahren, mit dem die Wirksamkeit von
                                    Marketingmaßnahmen festgestellt werden kann.
                                    Dazu wird im Regelfall ein Cookie auf den
                                    Geräten der Nutzer innerhalb der Webseiten,
                                    auf denen die Marketingmaßnahmen erfolgen,
                                    gespeichert und dann erneut auf der
                                    Zielwebseite abgerufen. Beispielsweise
                                    können wir so nachvollziehen, ob die von uns
                                    auf anderen Webseiten geschalteten Anzeigen
                                    erfolgreich waren.{' '}
                                </li>
                                <li>
                                    <strong>
                                        Meta-, Kommunikations- und
                                        Verfahrensdaten:
                                    </strong>{' '}
                                    Meta-, Kommunikations- und Verfahrensdaten
                                    sind Kategorien, die Informationen über die
                                    Art und Weise enthalten, wie Daten
                                    verarbeitet, übermittelt und verwaltet
                                    werden. Meta-Daten, auch bekannt als Daten
                                    über Daten, umfassen Informationen, die den
                                    Kontext, die Herkunft und die Struktur
                                    anderer Daten beschreiben. Sie können
                                    Angaben zur Dateigröße, dem
                                    Erstellungsdatum, dem Autor eines Dokuments
                                    und den Änderungshistorien beinhalten.
                                    Kommunikationsdaten erfassen den Austausch
                                    von Informationen zwischen Nutzern über
                                    verschiedene Kanäle, wie E-Mail-Verkehr,
                                    Anrufprotokolle, Nachrichten in sozialen
                                    Netzwerken und Chat-Verläufe, inklusive der
                                    beteiligten Personen, Zeitstempel und
                                    Übertragungswege. Verfahrensdaten
                                    beschreiben die Prozesse und Abläufe
                                    innerhalb von Systemen oder Organisationen,
                                    einschließlich Workflow-Dokumentationen,
                                    Protokolle von Transaktionen und
                                    Aktivitäten, sowie Audit-Logs, die zur
                                    Nachverfolgung und Überprüfung von Vorgängen
                                    verwendet werden.{' '}
                                </li>
                                <li>
                                    <strong>Nutzungsdaten:</strong>{' '}
                                    Nutzungsdaten beziehen sich auf
                                    Informationen, die erfassen, wie Nutzer mit
                                    digitalen Produkten, Dienstleistungen oder
                                    Plattformen interagieren. Diese Daten
                                    umfassen eine breite Palette von
                                    Informationen, die aufzeigen, wie Nutzer
                                    Anwendungen nutzen, welche Funktionen sie
                                    bevorzugen, wie lange sie auf bestimmten
                                    Seiten verweilen und über welche Pfade sie
                                    durch eine Anwendung navigieren.
                                    Nutzungsdaten können auch die Häufigkeit der
                                    Nutzung, Zeitstempel von Aktivitäten,
                                    IP-Adressen, Geräteinformationen und
                                    Standortdaten einschließen. Sie sind
                                    besonders wertvoll für die Analyse des
                                    Nutzerverhaltens, die Optimierung von
                                    Benutzererfahrungen, das Personalisieren von
                                    Inhalten und das Verbessern von Produkten
                                    oder Dienstleistungen. Darüber hinaus
                                    spielen Nutzungsdaten eine entscheidende
                                    Rolle beim Erkennen von Trends, Vorlieben
                                    und möglichen Problembereichen innerhalb
                                    digitaler Angebote{' '}
                                </li>
                                <li>
                                    <strong>Personenbezogene Daten:</strong>{' '}
                                    "Personenbezogene Daten" sind alle
                                    Informationen, die sich auf eine
                                    identifizierte oder identifizierbare
                                    natürliche Person (im Folgenden "betroffene
                                    Person") beziehen; als identifizierbar wird
                                    eine natürliche Person angesehen, die direkt
                                    oder indirekt, insbesondere mittels
                                    Zuordnung zu einer Kennung wie einem Namen,
                                    zu einer Kennnummer, zu Standortdaten, zu
                                    einer Online-Kennung (z. B. Cookie) oder zu
                                    einem oder mehreren besonderen Merkmalen
                                    identifiziert werden kann, die Ausdruck der
                                    physischen, physiologischen, genetischen,
                                    psychischen, wirtschaftlichen, kulturellen
                                    oder sozialen Identität dieser natürlichen
                                    Person sind.{' '}
                                </li>
                                <li>
                                    <strong>
                                        Profile mit nutzerbezogenen
                                        Informationen:
                                    </strong>{' '}
                                    Die Verarbeitung von "Profilen mit
                                    nutzerbezogenen Informationen", bzw. kurz
                                    "Profilen" umfasst jede Art der
                                    automatisierten Verarbeitung
                                    personenbezogener Daten, die darin besteht,
                                    dass diese personenbezogenen Daten verwendet
                                    werden, um bestimmte persönliche Aspekte,
                                    die sich auf eine natürliche Person beziehen
                                    (je nach Art der Profilbildung können dazu
                                    unterschiedliche Informationen betreffend
                                    die Demographie, Verhalten und Interessen,
                                    wie z. B. die Interaktion mit Webseiten und
                                    deren Inhalten, etc.) zu analysieren, zu
                                    bewerten oder, um sie vorherzusagen (z. B.
                                    die Interessen an bestimmten Inhalten oder
                                    Produkten, das Klickverhalten auf einer
                                    Webseite oder den Aufenthaltsort). Zu
                                    Zwecken des Profilings werden häufig Cookies
                                    und Web-Beacons eingesetzt.{' '}
                                </li>
                                <li>
                                    <strong>Protokolldaten:</strong>{' '}
                                    Protokolldaten sind Informationen über
                                    Ereignisse oder Aktivitäten, die in einem
                                    System oder Netzwerk protokolliert wurden.
                                    Diese Daten enthalten typischerweise
                                    Informationen wie Zeitstempel, IP-Adressen,
                                    Benutzeraktionen, Fehlermeldungen und andere
                                    Details über die Nutzung oder den Betrieb
                                    eines Systems. Protokolldaten werden oft zur
                                    Analyse von Systemproblemen, zur
                                    Sicherheitsüberwachung oder zur Erstellung
                                    von Leistungsberichten verwendet.{' '}
                                </li>
                                <li>
                                    <strong>Reichweitenmessung:</strong> Die
                                    Reichweitenmessung (auch als Web Analytics
                                    bezeichnet) dient der Auswertung der
                                    Besucherströme eines Onlineangebotes und
                                    kann das Verhalten oder Interessen der
                                    Besucher an bestimmten Informationen, wie
                                    z. B. Inhalten von Webseiten, umfassen. Mit
                                    Hilfe der Reichweitenanalyse können
                                    Betreiber von Onlineangeboten z. B.
                                    erkennen, zu welcher Zeit Nutzer ihre
                                    Webseiten besuchen und für welche Inhalte
                                    sie sich interessieren. Dadurch können sie
                                    z. B. die Inhalte der Webseiten besser an
                                    die Bedürfnisse ihrer Besucher anpassen. Zu
                                    Zwecken der Reichweitenanalyse werden häufig
                                    pseudonyme Cookies und Web-Beacons
                                    eingesetzt, um wiederkehrende Besucher zu
                                    erkennen und so genauere Analysen zur
                                    Nutzung eines Onlineangebotes zu erhalten.{' '}
                                </li>
                                <li>
                                    <strong>Tracking:</strong> Vom "Tracking"
                                    spricht man, wenn das Verhalten von Nutzern
                                    über mehrere Onlineangebote hinweg
                                    nachvollzogen werden kann. Im Regelfall
                                    werden im Hinblick auf die genutzten
                                    Onlineangebote Verhaltens- und
                                    Interessensinformationen in Cookies oder auf
                                    Servern der Anbieter der
                                    Trackingtechnologien gespeichert
                                    (sogenanntes Profiling). Diese Informationen
                                    können anschließend z. B. eingesetzt werden,
                                    um den Nutzern Werbeanzeigen anzuzeigen, die
                                    voraussichtlich deren Interessen
                                    entsprechen.{' '}
                                </li>
                                <li>
                                    <strong>Verantwortlicher:</strong> Als
                                    "Verantwortlicher" wird die natürliche oder
                                    juristische Person, Behörde, Einrichtung
                                    oder andere Stelle, die allein oder
                                    gemeinsam mit anderen über die Zwecke und
                                    Mittel der Verarbeitung von
                                    personenbezogenen Daten entscheidet,
                                    bezeichnet.{' '}
                                </li>
                                <li>
                                    <strong>Verarbeitung:</strong>{' '}
                                    "Verarbeitung" ist jeder mit oder ohne Hilfe
                                    automatisierter Verfahren ausgeführte
                                    Vorgang oder jede solche Vorgangsreihe im
                                    Zusammenhang mit personenbezogenen Daten.
                                    Der Begriff reicht weit und umfasst
                                    praktisch jeden Umgang mit Daten, sei es das
                                    Erheben, das Auswerten, das Speichern, das
                                    Übermitteln oder das Löschen.{' '}
                                </li>
                                <li>
                                    <strong>Vertragsdaten:</strong>{' '}
                                    Vertragsdaten sind spezifische
                                    Informationen, die sich auf die
                                    Formalisierung einer Vereinbarung zwischen
                                    zwei oder mehr Parteien beziehen. Sie
                                    dokumentieren die Bedingungen, unter denen
                                    Dienstleistungen oder Produkte
                                    bereitgestellt, getauscht oder verkauft
                                    werden. Diese Datenkategorie ist wesentlich
                                    für die Verwaltung und Erfüllung
                                    vertraglicher Verpflichtungen und umfasst
                                    sowohl die Identifikation der
                                    Vertragsparteien als auch die spezifischen
                                    Bedingungen und Konditionen der
                                    Vereinbarung. Vertragsdaten können Start-
                                    und Enddaten des Vertrages, die Art der
                                    vereinbarten Leistungen oder Produkte,
                                    Preisvereinbarungen, Zahlungsbedingungen,
                                    Kündigungsrechte, Verlängerungsoptionen und
                                    spezielle Bedingungen oder Klauseln
                                    umfassen. Sie dienen als rechtliche
                                    Grundlage für die Beziehung zwischen den
                                    Parteien und sind entscheidend für die
                                    Klärung von Rechten und Pflichten, die
                                    Durchsetzung von Ansprüchen und die Lösung
                                    von Streitigkeiten.{' '}
                                </li>
                                <li>
                                    <strong>Zahlungsdaten:</strong>{' '}
                                    Zahlungsdaten umfassen sämtliche
                                    Informationen, die zur Abwicklung von
                                    Zahlungstransaktionen zwischen Käufern und
                                    Verkäufern benötigt werden. Diese Daten sind
                                    von entscheidender Bedeutung für den
                                    elektronischen Handel, das Online-Banking
                                    und jede andere Form der finanziellen
                                    Transaktion. Sie beinhalten Details wie
                                    Kreditkartennummern, Bankverbindungen,
                                    Zahlungsbeträge, Transaktionsdaten,
                                    Verifizierungsnummern und
                                    Rechnungsinformationen. Zahlungsdaten können
                                    auch Informationen über den Zahlungsstatus,
                                    Rückbuchungen, Autorisierungen und Gebühren
                                    enthalten.{' '}
                                </li>
                                <li>
                                    <strong>Zielgruppenbildung:</strong> Von
                                    Zielgruppenbildung (englisch "Custom
                                    Audiences") spricht man, wenn Zielgruppen
                                    für Werbezwecke, z. B. Einblendung von
                                    Werbeanzeigen bestimmt werden. So kann z. B.
                                    anhand des Interesses eines Nutzers an
                                    bestimmten Produkten oder Themen im Internet
                                    geschlussfolgert werden, dass dieser Nutzer
                                    sich für Werbeanzeigen für ähnliche Produkte
                                    oder den Onlineshop, in dem er die Produkte
                                    betrachtet hat, interessiert. Von "Lookalike
                                    Audiences" (bzw. ähnlichen Zielgruppen)
                                    spricht man wiederum, wenn die als geeignet
                                    eingeschätzten Inhalte Nutzern angezeigt
                                    werden, deren Profile, bzw. Interessen
                                    mutmaßlich den Nutzern zu denen die Profile
                                    gebildet wurden, entsprechen. Zur Zwecken
                                    der Bildung von Custom Audiences und
                                    Lookalike Audiences werden im Regelfall
                                    Cookies und Web-Beacons eingesetzt.{' '}
                                </li>
                            </ul>
                        </ContentBlock>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
