import { Button, Stack, TextField, useTheme } from '@mui/material';
import { Checkbox } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';

import { useState } from 'react';
interface FormData {
    name: string;
    company: string;
    email: string;
    content: string;
    consent: boolean;
}

export function ContactForm() {
    const theme = useTheme();

    const [formData, setFormData] = useState<FormData>({
        name: '',
        company: '',
        email: '',
        content: '',
        consent: false,
    });

    const handleSubmit = (e: any) => {
        e.preventDefault();
        // this function will send un an email on info@punitech.de
        // the form submission will also create a lead directly in the CRM Vtiger
        fetch('https://getform.io/f/ayvpglpb', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: formData.name,
                company: formData.company,
                email: formData.email,
                message: formData.content,
            }),
        }).then(() => {
            setFormData({
                name: '',
                company: '',
                email: '',
                content: '',
                consent: false,
            });
        });
        e.target.submit();
    };

    const changeValueAction = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
        if (event.target.name === 'consent') {
            setFormData({
                ...formData,
                [event.target.name]: (event.target as HTMLInputElement).checked,
            });

            return;
        }
    };

    return (
        <form
            onSubmit={handleSubmit}
            id="__vtigerWebForm_2"
            name="define_business_lead_form"
            action="https://punitechgmbh.od2.vtiger.com/modules/Webforms/capture.php"
            method="post"
            acceptCharset="utf-8"
            encType="multipart/form-data"
        >
            <input
                type="hidden"
                name="publicid"
                value="efc112475c58e3933a1536e66d45c60b"
            />
            <input type="hidden" name="urlencodeenable" value="1" />
            <input
                type="hidden"
                name="name"
                value="define_business_lead_form"
            />
            <input type="hidden" name="__vtCurrency" value="1" />

            <Stack spacing={2}>
                <TextField
                    name="name"
                    onChange={changeValueAction}
                    label={'Ihr Name'}
                    variant={'outlined'}
                    required={true}
                    fullWidth={true}
                    InputProps={{
                        sx: {
                            backgroundColor: theme.palette.background.paper,
                        },
                    }}
                />
                <TextField
                    name="company"
                    onChange={changeValueAction}
                    label={'Firmen Name'}
                    variant={'outlined'}
                    fullWidth={true}
                    InputProps={{
                        sx: {
                            backgroundColor: theme.palette.background.paper,
                        },
                    }}
                />
                <TextField
                    name="email"
                    onChange={changeValueAction}
                    label={'E-Mail'}
                    required={true}
                    variant={'outlined'}
                    fullWidth={true}
                    InputProps={{
                        sx: {
                            backgroundColor: theme.palette.background.paper,
                        },
                    }}
                />
                <TextField
                    name="content"
                    onChange={changeValueAction}
                    label={'Ihre Anfrage'}
                    required={true}
                    variant={'outlined'}
                    multiline={true}
                    fullWidth={true}
                    InputProps={{
                        sx: {
                            backgroundColor: theme.palette.background.paper,
                            minHeight: '100px',
                        },
                    }}
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={changeValueAction}
                            name="consent"
                            color="primary"
                            checked={formData.consent}
                        />
                    }
                    label="Durch das Absenden dieses Formulars stimmen Sie unseren
                Datenschutzrichtlinien zu und verstehen, wie Ihre Informationen
                verwendet werden."
                ></FormControlLabel>

                <Button
                    type="submit"
                    variant={'outlined'}
                    disabled={!formData.consent}
                >
                    Jetzt abschicken
                </Button>
            </Stack>
        </form>
    );
}
