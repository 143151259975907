import React from 'react';
import { Route, useLocation } from 'react-router-dom';

import { MainContainer } from '../layout/MainContainer';
import { ContactFormResponsePage } from '../pages/contactFormResponse/ContactFormResponsePage';
import { ImpressumPage } from '../pages/impressum/ImpressumPage';
import { StartPage } from '../pages/start/StartPage';
import { DataProtection } from '../pages/dataProtection/DataProtection';

export const publicRoutesIndex = () => {
    const location = useLocation(); // Hook to get location object
    const searchParams = new URLSearchParams(location.search); // Extract query parameters
    const success = searchParams.get('success'); // Get 'success' parameter value

    return (
        <Route element={<MainContainer />}>
            {/* Conditionally render ContactFormSuccess if success is 'ok' */}
            <Route
                key="startpage"
                path="/"
                element={
                    success === 'ok' ? (
                        <ContactFormResponsePage />
                    ) : (
                        <StartPage />
                    )
                }
            />
            <Route
                key="impressum"
                path="/impressum"
                element={<ImpressumPage />}
            />
            <Route
                key="datenschutz"
                path="/datenschutz"
                element={<DataProtection />}
            />
        </Route>
    );
};
