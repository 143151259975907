import { useTheme } from '@mui/material';
import CookieConsent from 'react-cookie-consent';
import { FooterLink } from '../../layout/MainFooter';
import { useNavigate } from 'react-router-dom';

export const CookieConsentComponent = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    function linkClickAction(
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) {
        event.preventDefault();
        navigate('' + event.currentTarget.dataset.url);
    }

    return (
        <CookieConsent
            location="bottom"
            buttonText="Akzeptieren"
            cookieName="data_protection"
            style={{ background: theme.palette.primary.main }}
            buttonStyle={{
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.background.paper,
                fontSize: '22px',
            }}
            declineButtonText="Ablehnen"
            enableDeclineButton
            declineButtonStyle={{
                color: theme.palette.primary.light,
                backgroundColor: theme.palette.error.main,
                fontSize: '22px',
            }}
            expires={150}
        >
            Datenschutz und Nutzungserlebnis auf Define Business: Mehr lesen{' '}
            <FooterLink
                variant={'body1'}
                marginTop={2}
                marginBottom={2}
                sx={{
                    textDecoration: 'underline',
                }}
                onClick={linkClickAction}
                data-url={'/datenschutz'}
                href={'/datenschutz'}
            >
                Datenschutzerklärung
            </FooterLink>
        </CookieConsent>
    );
};
