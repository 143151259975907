import { ThemeOptions, createTheme } from '@mui/material/styles';

export const themeOptions: ThemeOptions = {
    typography: {
        h1: {
            fontSize: '24px',
            fontFamily: '"Kanit", sans-serif',
            fontWeight: '600',
        },
        h2: {
            fontSize: '22px',
            fontFamily: '"Kanit", sans-serif',
            fontWeight: '600',
        },
        h3: {
            fontSize: '20px',
            fontFamily: '"Kanit", sans-serif',
            fontWeight: '500',
        },
        body1: {
            fontFamily: '"Kanit", sans-serif',
            fontWeight: '300',
        },
        body2: {
            fontFamily: '"Kanit", sans-serif',
            fontWeight: '300',
        },
    },
    palette: {
        mode: 'light',
        primary: {
            main: '#15273c', // "#0b1127",
            light: '#ffffff',
        },
        secondary: {
            main: '#3a1e14', // "#d9b99b",
        },
        action: {
            active: '#333b4d',
        },
        text: {
            primary: '#333b4d',
        },
        /*
        action: {
            active: "#0b1127",
            hover: "#d9b99b",
            hoverOpacity: 0.7,
            focus: "#d9b99b",
            focusOpacity: 1,
            selected: blueGrey[300],
            selectedOpacity: 1,
        },*/
    },
    shape: {
        borderRadius: 8,
    },
    components: {
        MuiAccordion: {
            styleOverrides: {
                root: {
                    // border: "solid red",
                    '&.MuiAccordion-root': {
                        padding: '16px',
                    },
                    '&.Mui-expanded': {
                        background: '#d9b99b',
                    },
                    '.MuiAccordionSummary-gutters': {
                        width: '350px',
                    },
                    '.MuiAccordionSummary-root': {
                        padding: '0 !important',
                    },
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: ({ theme }) => ({
                    marginBottom: theme.spacing(2),
                    '&.show-changes input': {
                        background: '#ccefff',
                    },
                    '.show-changes': {
                        border: '1px solid blue',
                    },
                }),
            },
        },
        MuiLink: {
            styleOverrides: {
                root: () => ({
                    textDecoration: 'none',
                }),
            },
        },
        MuiBreadcrumbs: {
            styleOverrides: {
                li: ({ theme }) => ({
                    color: theme.palette.secondary.main,
                    fontSize: '20px',
                }),
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    '&.dialog-mode .form-block--header': {
                        padding: '0px',
                        paddingBottom: '16px',
                    },
                    '&.dialog-mode .form-block--content': {
                        padding: '0px',
                    },
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    '&.form-block--header': {
                        paddingBottom: '0px',
                    },
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {},
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    '&.has-badge': {
                        paddingRight: '32px',
                    },
                    ' span.MuiBadge-badge': {
                        top: '8px',
                        right: '-16px',
                    },
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    paddingRight: '24px',
                    paddingBottom: '24px',
                },
            },
        },
    },
};

export const theme = createTheme(themeOptions);
